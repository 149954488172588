import { styled } from '@mui/system';
import {
  Table, TableContainer, TableCell, TableRow, TextField, InputAdornment,
  TablePagination, Checkbox, TableSortLabel, Box, Modal, IconButton, Typography, Select, Switch
} from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';

export const TableContainerStyled = styled(TableContainer)({
  backgroundColor: '#32384B',
  color: '#FFFFFF',
  border: '1.5px solid #FFFFFF',
  borderRadius: '8px',
  overflowX: 'auto',  // Ensure horizontal scrolling
  display: 'block',   // Allow block display for better scrolling
});

export const TableStyled = styled(Table)({
  width: '100%',  // Ensure table takes full width of the container
});

export const TableCellStyled = styled(TableCell)({
  border: '1px solid #D3D3D3',
  color: '#FFFFFF',
  whiteSpace: 'nowrap',
  padding: '8px', // Adjust padding to fit content
});

export const TableCellHeadStyled = styled(TableCell)({
  backgroundColor: '#060d1f',
  color: '#FFFFFF',
  fontWeight: 'bold',
  border: '1.5px solid #FFFFFF',
  borderBottom: 'none',
  whiteSpace: 'nowrap',
  '& .MuiTableSortLabel-root': {
    color: '#FFFFFF !important',
    '&.MuiTableSortLabel-active': {
      color: '#FFFFFF',
    },
    '& .MuiTableSortLabel-icon': {
      color: '#FFFFFF !important',
    },
  },
});

export const TableRowHeadStyled = styled(TableRow)({
  '& > th:first-of-type': {
    borderTopLeftRadius: '8px',
  },
  '& > th:last-of-type': {
    borderTopRightRadius: '8px',
  },
});

export const TableRowStyled = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#3b3f52',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#2d3143',
  },
});

export const TextFieldStyled = styled(TextField)({
  width: '100%',
  '& .MuiInputBase-root': {
    padding: '8px',
    fontSize: 'inherit',
    color: '#FFFFFF',
    height: '40px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
    },
    '&:hover fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ff7e04',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#A0A0A0',
    fontSize: '1rem',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ff7e04',
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  marginBottom: '16px',
});

export const MultiRowTextFieldStyled = styled(TextField)({
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
    height: 'auto',
    minHeight: '160px',
    alignItems: 'flex-start',
    padding: '8px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  '& .MuiInputLabel-root': {
    color: '#A0A0A0',
    fontSize: '1rem',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
    },
    '&:hover fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ff7e04',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ff7e04',
  },
  '& textarea': {
    height: '160px',
    resize: 'vertical',
    color: '#FFFFFF',
    padding: '8px',
  },
});


export const InputAdornmentStyled = styled(InputAdornment)({
  '& .MuiTypography-root': {
    color: '#FFFFFF',
    marginLeft: '2px',
  },
});

export const TablePaginationStyled = styled(TablePagination)({
  color: '#FFFFFF',
  '& .MuiSelect-icon': {
    color: '#FFFFFF',
  },
  '& .MuiTablePagination-actions': {
    color: '#FFFFFF',
  },
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
  },
});

export const CheckboxStyled = styled(Checkbox)({
  color: '#FFFFFF',
  '&.Mui-checked': {
    color: '#ff7e04',
  },
  '& .MuiSvgIcon-root': {
    color: 'inherit',
  },
  '&.MuiCheckbox-indeterminate': {
    color: '#ff7e04',
  },
});

export const SwitchStyled = styled(Switch)({
  color: '#FFFFFF',
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#ff7e04',
    '&:hover': {
      backgroundColor: 'rgba(255, 126, 4, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#ff7e04',
  },
});

export const TableSortLabelStyled = styled(TableSortLabel)(({ theme, active }) => ({
  color: '#FFFFFF',
  fontWeight: active ? 'bold' : 'bold',
  '&:hover': {
    color: '#FFFFFF',
  },
  '&.Mui-active': {
    color: '#FFFFFF',
  },
  '& .MuiTableSortLabel-icon': {
    color: '#FFFFFF !important',
  },
}));

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const ImageModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ImageContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#060d1f',
  padding: '20px',
  borderRadius: '8px',
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflow: 'auto',
});

export const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '1px',
  right: '1px',
  color: '#d60000',
});

export const ImageTitle = styled(Typography)({
  color: '#FFFFFF',
  marginBottom: '10px',
  textAlign: 'center',
  wordWrap: 'break-word',
  width: 'calc(100% - 40px)',
  overflowWrap: 'break-word',
  hyphens: 'auto',
  padding: '0 20px',
});

export const CustomModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ModalContent = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#060d1f',
  padding: '20px',
  borderRadius: '8px',
  width: '30%',
  height: '30%',
  maxWidth: '80vw',
  maxHeight: '80vh',
  overflow: 'auto',
  color: '#FFFFFF',
  '@media (max-width: 900px)': {
    width: '80%',
    height: '80%',
  },
});

export const ModalCloseButton = styled(IconButton)({
  position: 'absolute',
  top: '10px',
  right: '10px',
  color: '#d60000',
});

export const ModalTitle = styled(Typography)({
  color: '#FFFFFF',
  marginBottom: '10px',
  textAlign: 'center',
  fontWeight: 'bold',
});

export const ModalSubtitle = styled(Typography)({
  color: '#A0A0A0',
  marginBottom: '20px',
  textAlign: 'center',
});

export const ModalMessage = styled(Typography)({
  color: '#FFFFFF',
  marginBottom: '20px',
  textAlign: 'center',
});

export const SpinnerContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export const Spinner = styled(ClipLoader)({
  color: '#ff7e04',
});

export const SelectStyled = styled(Select)({
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
  },
  '& .MuiInputLabel-root': {
    color: '#A0A0A0',
    fontSize: '1rem',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
    },
    '&:hover fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ff7e04',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ff7e04',
  },
  '& .MuiSelect-icon': {
    color: '#FFFFFF',
  },
});