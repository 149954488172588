import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledContainer = styled(Container)({
  padding: '16px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  boxSizing: 'border-box',
  minHeight: '100vh',
  position: 'relative',
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const InteractiveChatGames = () => {
  return (
    <StyledContainer>
      <Header>
        <Typography variant="h4" gutterBottom>
          Interactive Chat Games
        </Typography>
        <IconButton component={Link} to="/features" sx={{ color: '#FFFFFF' }}>
          <ArrowBackIcon />
        </IconButton>
      </Header>
      <Typography variant="body1" gutterBottom>
        Fun and entertaining games viewers can join in chat for fun with their loyalty tokens. Users will have the option to enable whichever games they like from many options. These mini-games will give viewers fun ways to wager their tokens in chat. These interactive chat games will have solo and multiplayer options to make sure there's plenty of fun for your chat to partake in. Each game has great user control with many options to customize each experience. By using simple commands, viewers will be able to activate and enjoy games such as:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          1. <strong>Treasure Chest:</strong> This is a chest that appears in chat for the viewers being aware and active to claim. The user chooses if it's claimable by a simple command or a generic math problem to make it more fun and difficult for bots to claim. It is also a choice for how much currency the user wants each person to get from a claimed chest. Also, you get to choose how many of these chests appear per each 10 viewers. This gives plenty of flexibility and options to dial the experience and distribution of currency to the user's liking.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. <strong>Boss Battle:</strong> The boss battles are going to be a very fun and interactive way for chat to work together to be rewarded. The user will be able to have customization options to create this battle experience perfect for their community. The way the boss is triggered can be set to the user summoning the boss, going off of a timer, or even triggered once so many points have been deposited into the boss from taxing other chat games. When this boss comes into chat it is announced in the stream and in future updates on an overlay. The chat does different commands to help attack this boss as the boss is fighting back. If a user is defeated they are out of the battle and can't help. The boss will have a random time to be defeated and if time expires before his hp is 0 the boss wins and leaves. However, if he is defeated he drops his loot and the people that helped fight are now rewarded. Future updates will be the ability to use weapon or shield NFTs to help in these battles, as well as the ability to reward those who help more differently.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. <strong>Treasure Hunt:</strong> This treasure hunt can be funded by two options chosen by the user. It could be funded by the house set by a timer to activate. Or it could be funded by viewers buying in after being triggered by a command. With this option, you could have a set buy-in cost or one set with the command. With the timed version users can set a cost to help fund boss battles or allow free entry. Once activated it will allow viewers to enter the treasure hunt for a set amount of time. Once the hunt starts it will display fun messages of events during the hunt including showing users having actions that help advance or kill them. After a few fun events, the hunt ends and the viewers still alive share the prize pool. This gives the community a fun way to risk their coins to come back with more as well as help build the boss battle prize.
        </Typography>
        <Typography variant="body1" gutterBottom>
          4. <strong>Rumble:</strong> This is a very high risk high reward game for your community to enjoy. A viewer can run this command to start a rumble with x amount of currency to buy in. After the set amount of time the rumble starts. It shows events in chat of people attacking and destroying each other until only one remains!!! That viewer gets the prize pool from entries. However, the user can set a percentage to go to providing prize pool to the Boss Battle.
        </Typography>
        <Typography variant="body1" gutterBottom>
          5. <strong>Casino:</strong> These are going to be a set of standard known casino games now and more unique ones down the road. The user can define an overall rate of what the casino takes in vs pays out. Viewers will be able to run these games in chat through commands. They can risk their coins with these casino games in the chance to earn more in return. Some of these games include:
        </Typography>
        <Box sx={{ marginLeft: 4 }}>
          <Typography variant="body1" gutterBottom>
            ➢ <strong>Roulette:</strong> Pick a number for a chance at a 30x return, or a color for a chance to double your amount.
          </Typography>
          <Typography variant="body1" gutterBottom>
            ➢ <strong>Roshambo:</strong> Risk an amount and pick rock paper scissors to try to beat Scurvy Bot for double the prize.
          </Typography>
          <Typography variant="body1" gutterBottom>
            ➢ <strong>Coin Flip:</strong> Enter heads or tails along with bet amount and the result is shown in chat.
          </Typography>
          <Typography variant="body1" gutterBottom>
            ➢ <strong>Crash:</strong> Start with activating crash as well as your base bet. Every 5 seconds it displays in chat the current multiplier or a crash event. The viewer must type !claimcrash before they see a crash event to win the last displayed multiplier.
          </Typography>
          <Typography variant="body1" gutterBottom>
            ➢ <strong>Slots:</strong> Spin command and your bet. The slots will be a match three or nothing concept. There will be different odds for each symbol for the payout. Once they run the command it shows in chat what their result is.
          </Typography>
        </Box>
      </Box>
      <Typography variant="body1" gutterBottom>
        These casino games combined with controlling the overall pay structure is a wonderful way for your community to have countless ways to use their currency without flooding the loyalty currency with new printed currency. It is also a great way for your community to help fund the boss battle. This will happen if you set the house odds to be + X% and have boss battle taxing enabled then that house profit would go to boss battle. If not it would just theoretically be burned.
      </Typography>
    </StyledContainer>
  );
};

export default InteractiveChatGames;
