import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, TableSortLabel } from '@mui/material';
import { styled } from '@mui/system';

const TableContainerStyled = styled(TableContainer)({
  backgroundColor: '#32384B',
  color: '#FFFFFF',
  border: '1.5px solid #FFFFFF',
  borderRadius: '8px',
  overflow: 'hidden',
});

const TableCellStyled = styled(TableCell)({
  border: '1px solid #D3D3D3',
  color: '#FFFFFF',
});

const TableCellHeadStyled = styled(TableCell)({
  backgroundColor: '#060d1f',
  color: '#FFFFFF',
  fontWeight: 'bold',
  border: '1.5px solid #FFFFFF',
  borderBottom: 'none',
  '& .MuiTableSortLabel-root': {
    color: '#FFFFFF !important',
    '&.MuiTableSortLabel-active': {
      color: '#FFFFFF',
    },
    '& .MuiTableSortLabel-icon': {
      color: '#FFFFFF !important',
    },
  },
});

const TableRowHeadStyled = styled(TableRow)({
  '& > th:first-of-type': {
    borderTopLeftRadius: '8px',
  },
  '& > th:last-of-type': {
    borderTopRightRadius: '8px',
  },
});

const TableRowStyled = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#3b3f52',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#2d3143',
  },
});

const sampleData = [
  {
    type: 'Deposit',
    token: 'SMP1 (sample.token1)',
    amount: 500,
    wallet: '0x123456789abcdef',
    transactionReference: 'b786c799d1c90be9a621284c3a5764d7a2fdb1ba8a09df5a7c27c859c8696035',
    date: '2024-06-01',
  },
  {
    type: 'Withdraw',
    token: 'SMP2 (sample.token2)',
    amount: 300,
    wallet: '0xabcdef123456789',
    transactionReference: 'f21a6b838ef3ff20db61100924a965323fbfca29193b72631e182809d78c688b',
    date: '2024-06-02',
  },
  // Add more sample data as needed
];

const StreamerTransactionHistory = () => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = [...sampleData].sort((a, b) => {
    if (orderBy === 'amount') {
      return order === 'asc' ? a.amount - b.amount : b.amount - a.amount;
    } else if (orderBy === 'type' || orderBy === 'token' || orderBy === 'wallet' || orderBy === 'transactionReference') {
      return order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
    } else {
      return order === 'asc' ? new Date(a[orderBy]) - new Date(b[orderBy]) : new Date(b[orderBy]) - new Date(a[orderBy]);
    }
  });

  return (
    <Box sx={{ padding: '16px' }}>
      <Typography variant="h5" color="#FFFFFF" gutterBottom>
        Transaction History
      </Typography>
      <Typography variant="body1" color="#A0A0A0" paragraph>
        View your token pool deposit and withdrawal history.
      </Typography>
      <TableContainerStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRowHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'type' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={() => handleRequestSort('type')}
                >
                  Type
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'token' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'token'}
                  direction={orderBy === 'token' ? order : 'asc'}
                  onClick={() => handleRequestSort('token')}
                >
                  Token
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'amount' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'amount'}
                  direction={orderBy === 'amount' ? order : 'asc'}
                  onClick={() => handleRequestSort('amount')}
                >
                  Amount
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'wallet' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'wallet'}
                  direction={orderBy === 'wallet' ? order : 'asc'}
                  onClick={() => handleRequestSort('wallet')}
                >
                  Wallet
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'transactionReference' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'transactionReference'}
                  direction={orderBy === 'transactionReference' ? order : 'asc'}
                  onClick={() => handleRequestSort('transactionReference')}
                >
                  Transaction Reference
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'date' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'date'}
                  direction={orderBy === 'date' ? order : 'desc'}
                  onClick={() => handleRequestSort('date')}
                >
                  Date
                </TableSortLabel>
              </TableCellHeadStyled>
            </TableRowHeadStyled>
          </TableHead>
          <TableBody>
            {sortedData.map((tx, index) => (
              <TableRowStyled key={index}>
                <TableCellStyled>{tx.type}</TableCellStyled>
                <TableCellStyled>{tx.token}</TableCellStyled>
                <TableCellStyled>{tx.amount}</TableCellStyled>
                <TableCellStyled>{tx.wallet}</TableCellStyled>
                <TableCellStyled>{tx.transactionReference}</TableCellStyled>
                <TableCellStyled>{tx.date}</TableCellStyled>
              </TableRowStyled>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
    </Box>
  );
};

export default StreamerTransactionHistory;
