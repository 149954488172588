import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { styled } from '@mui/system';
import { Paper, Typography, List, ListItem, Alert, Box, Grid, ListItemIcon } from '@mui/material';
import { Favorite, AttachMoney } from '@mui/icons-material';
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from './AuthContext';

const DashboardContainer = styled('div')({
  padding: '20px',
  backgroundColor: '#32384B',
  color: '#FFFFFF',
  boxSizing: 'border-box',
});

const Section = styled(Paper)({
  backgroundColor: '#424867',
  height: '420px',
  overflowY: 'auto',
  boxSizing: 'border-box',
  position: 'relative',
});

const Title = styled(Typography)({
  color: '#FFFFFF',
  marginBottom: '20px',
});

const SubTitle = styled(Typography)({
  color: '#FFFFFF',
  padding: '10px 0',
  backgroundColor: '#2C2F3E', // Darker background for the header
  borderRadius: '4px 4px 0 0', // Rounded corners only on top
  boxSizing: 'border-box',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  textAlign: 'center', // Center the title
});

const CustomList = styled(List)({
  padding: '10px', // Padding to add a gap below the heading
  paddingTop: '0', // Remove top padding from the list itself
});

const CustomListItem = styled(ListItem)({
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#FFFFFF', // Light background for list items
  color: '#000000', // Dark text color for contrast
  margin: '4px 0',
  borderRadius: '4px',
  boxSizing: 'border-box',
});

const ActivityText = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const DateText = styled('span')({
  color: '#606060', // Darker gray color for better contrast on white
  marginLeft: '4px',
});

const StreamerDashboard = () => {
  const { authState } = useAuth();
  const [recentActivity, setRecentActivity] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [liveStreamInfo, setLiveStreamInfo] = useState(null);
  const [recentFollowers, setRecentFollowers] = useState([]);
  const tooltipRefs = useRef([]);

  useEffect(() => {
    const fetchRecentActivity = async () => {
      try {
        const response = await axios.get('/api/twitch/recent-activity', {
          withCredentials: true,
        });
        const data = response.data;

        // Separate followers and subscribers for the recent followers/subscribers section
        const followers = data.filter(item => item.type === 'follow');
        const subscribers = data.filter(item => item.type === 'subscribe');

        setRecentFollowers([...followers, ...subscribers]);

        // Filter out follows and subscriptions from recent activity
        const activity = data.filter(item => item.type !== 'follow' && item.type !== 'subscribe');
        setRecentActivity(activity);
      } catch (error) {
        console.error('Error fetching recent activity:', error);
      }
    };

    fetchRecentActivity();
  }, [authState.twitch_id]);

  const getDisplayType = (type) => {
    switch (type) {
      case 'follow':
        return 'followed you';
      case 'subscribe':
        return 'subscribed';
      default:
        return type;
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 'follow':
        return <Favorite style={{ color: '#ff7e04', margin: '0 7px' }} />; // Adjust icon padding
      case 'subscribe':
        return <AttachMoney style={{ color: 'green', margin: '0 7px' }} />; // Adjust icon padding
      default:
        return null;
    }
  };

  const formatDate = (date) => new Date(date).toLocaleDateString(); // Format the date to the user's locale string

  return (
    <DashboardContainer>
      <Title variant="h5" gutterBottom>Streamer Dashboard</Title>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Section>
            <SubTitle variant="h6">Recent Activity</SubTitle>
            <CustomList>
              {recentActivity.map((activity, index) => (
                <CustomListItem key={index}>
                  <ActivityText>
                    {activity.description || `${activity.user_name} ${getDisplayType(activity.type)}`}
                    <DateText>{formatDistanceToNow(new Date(activity.timestamp), { addSuffix: true })}</DateText>
                  </ActivityText>
                </CustomListItem>
              ))}
            </CustomList>
          </Section>
        </Grid>

        <Grid item xs={12} md={4}>
          <Section>
            <SubTitle variant="h6">Recent Followers</SubTitle>
            <CustomList>
              {recentFollowers.map((follower, index) => (
                <CustomListItem key={index}>
                  <ListItemIcon style={{ minWidth: '24px' }}> {/* Reduce padding around icon */}
                    {getIcon(follower.type)}
                  </ListItemIcon>
                  <ActivityText>
                    <strong>{follower.user_name}</strong>&nbsp;{getDisplayType(follower.type)}&nbsp;
                    <DateText>{formatDistanceToNow(new Date(follower.timestamp), { addSuffix: true })}</DateText>
                  </ActivityText>
                </CustomListItem>
              ))}
            </CustomList>
          </Section>
        </Grid>

        <Grid item xs={12} md={4}>
          <Section>
            <SubTitle variant="h6">Notifications</SubTitle>
            <Box>
              {notifications.length === 0 ? (
                <Alert severity="success">No notifications</Alert>
              ) : (
                notifications.map((notification, index) => (
                  <Box key={index} mb={2}>
                    <Alert severity={notification.severity} style={{ padding: '10px' }}>{notification.message}</Alert>
                  </Box>
                ))
              )}
            </Box>
          </Section>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default StreamerDashboard;
