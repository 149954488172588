import React from 'react';
import { TextField, Button, Grid, Typography, Box, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { useFormik } from 'formik';
import * as yup from 'yup';

const Form = styled('form')({
  backgroundColor: '#32384B',
  padding: '16px',
  borderRadius: '8px',
});

const TextFieldStyled = styled(TextField)({
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
  },
  '& .MuiInputLabel-root': {
    color: '#A0A0A0',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
    },
    '&:hover fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ff7e04',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ff7e04',
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: '#32384B',
      color: '#FFFFFF',
    },
  },
};

const ButtonStyled = styled(Button)({
  backgroundColor: '#ff7e04',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#cc6503',
  },
});

const validationSchema = yup.object({
  name: yup.string('Enter your name').required('Name is required'),
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  twitchUsername: yup.string('Enter your Twitch username').required('Twitch username is required'),
  waxWallet: yup.string('Enter your Wax Wallet').required('Wax Wallet is required'),
  issueType: yup.string('Select an issue type').required('Issue type is required'),
  description: yup.string('Describe your issue').required('Description is required'),
});

const issueTypeOptions = [
  { value: 'bug', label: 'Bug Report' },
  { value: 'feature', label: 'Feature Request' },
  { value: 'account', label: 'Account Issue' },
  { value: 'billing', label: 'Billing Issue' },
  { value: 'other', label: 'Other' },
];

const SupportTicketForm = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      twitchUsername: '',
      waxWallet: '',
      issueType: '',
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Typography variant="h5" color="#FFFFFF" gutterBottom>
        Raise a Support Ticket
      </Typography>
      <Typography variant="body1" color="#A0A0A0" paragraph>
        Please fill out the form below to raise a support ticket if you have any problems or feature requests. Our team will get back to you shortly.
      </Typography>
      <Box mb={3} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldStyled
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldStyled
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldStyled
            fullWidth
            id="twitchUsername"
            name="twitchUsername"
            label="Twitch Username"
            value={formik.values.twitchUsername}
            onChange={formik.handleChange}
            error={formik.touched.twitchUsername && Boolean(formik.errors.twitchUsername)}
            helperText={formik.touched.twitchUsername && formik.errors.twitchUsername}
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldStyled
            fullWidth
            id="waxWallet"
            name="waxWallet"
            label="Wax Wallet"
            value={formik.values.waxWallet}
            onChange={formik.handleChange}
            error={formik.touched.waxWallet && Boolean(formik.errors.waxWallet)}
            helperText={formik.touched.waxWallet && formik.errors.waxWallet}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldStyled
            select
            fullWidth
            id="issueType"
            name="issueType"
            label="Issue Type"
            value={formik.values.issueType}
            onChange={formik.handleChange}
            error={formik.touched.issueType && Boolean(formik.errors.issueType)}
            helperText={formik.touched.issueType && formik.errors.issueType}
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              MenuProps: MenuProps,
            }}
          >
            {issueTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextFieldStyled>
        </Grid>
        <Grid item xs={12}>
          <TextFieldStyled
            fullWidth
            id="description"
            name="description"
            label="Description"
            multiline
            rows={4}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonStyled variant="contained" type="submit">
            Submit
          </ButtonStyled>
        </Grid>
      </Grid>
    </Form>
  );
};

export default SupportTicketForm;
