import React, { useState, useEffect } from 'react';
import {
  TableBody, TableHead, Paper, Typography, Box, IconButton, Tooltip, Switch, TableSortLabel
} from '@mui/material';
import {
  TableContainerStyled, TableStyled, TableCellStyled, TableCellHeadStyled,
  TableRowHeadStyled, TableRowStyled, TextFieldStyled, InputAdornmentStyled,
  TablePaginationStyled
} from './MasterStyles.js';
import axios from 'axios';
import { ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import Modal from './Modal';

const TokenPool = () => {
  const [pools, setPools] = useState([]);
  const [editRateId, setEditRateId] = useState(null);
  const [editRateValue, setEditRateValue] = useState('');
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [selectedPool, setSelectedPool] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('token_id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalPools, setTotalPools] = useState(0);

  useEffect(() => {
    fetchPools();
    fetchTotalPools();
  }, [orderBy, order, page, rowsPerPage]);

  const fetchPools = async () => {
    try {
      const response = await axios.get('/api/token-pools', {
        params: {
          sort_by: orderBy,
          sort_order: order,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        },
      });
      setPools(response.data.data);
    } catch (error) {
      console.error('Error fetching pools:', error);
    }
  };

  const fetchTotalPools = async () => {
    try {
      const response = await axios.get('/api/token-pools/count');
      setTotalPools(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total pools count:', error);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeposit = (pool) => {
    setSelectedPool(pool);
    setModalType('deposit');
    setOpen(true);
  };

  const handleWithdraw = (pool) => {
    setSelectedPool(pool);
    setModalType('withdraw');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setWithdrawAmount('');
    setWalletAddress('');
    setLoading(false);
    setIsSuccess(false);
    setIsError(false);
    setMessage('');
  };

  const handleActiveChange = async (pool) => {
    try {
      const updatedPool = { ...pool, is_active: !pool.is_active };
      await axios.post('/api/token-pools/update', {
        token_id: pool.token_id,
        is_active: updatedPool.is_active,
        rate: updatedPool.rate,
      });
      setPools((prevPools) =>
        prevPools.map((p) => (p.token_id === pool.token_id ? updatedPool : p))
      );
    } catch (error) {
      console.error('Error updating active status:', error);
    }
  };

  const handleRateClick = (poolId, rate) => {
    setEditRateId(poolId);
    setEditRateValue(rate);
  };

  const handleRateChange = (event) => {
    setEditRateValue(event.target.value);
  };

  const handleRateBlur = async (pool) => {
    try {
      await axios.post('/api/token-pools/update', {
        token_id: pool.token_id,
        rate: editRateValue,
        is_active: pool.is_active,
      });
      setPools((prevPools) =>
        prevPools.map((p) =>
          p.token_id === pool.token_id ? { ...p, rate: editRateValue } : p
        )
      );
      setEditRateId(null);
    } catch (error) {
      console.error('Error updating rate:', error);
    }
  };

  const handleConfirmWithdraw = async () => {
    const waxWalletRegex = /^[a-z1-5.]{1,12}$/;
    if (!withdrawAmount || !walletAddress) {
      setIsError(true);
      setMessage('Amount and WAX wallet address are mandatory.');
      return;
    }
    if (walletAddress && walletAddress.length > 0 && !waxWalletRegex.test(walletAddress)) {
      setIsError(true);
      setMessage('Invalid WAX wallet address.');
      return;
    }
    setLoading(true);
    // Simulate a delay for the transaction
    setTimeout(() => {
      setLoading(false);
      setIsSuccess(true);
      setMessage('Withdrawal successful.');
    }, 2000);
  };

  const getModalContent = () => {
    if (modalType === 'deposit') {
      return (
        <Box>
          <Typography variant="body1">
            Please follow this link to deposit tokens:
          </Typography>
          <Typography variant="body1" color="orange">
            <a
              href={`https://atomichub.io/trading/token-transfer?partner=redeem.sb&memo=deposit-${selectedPool?.twitch_id}-${selectedPool?.token_id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'orange', textDecoration: 'none' }}
            >
              https://atomichub.io/trading/token-transfer?partner=redeem.sb&memo=deposit-{selectedPool?.twitch_id}-{selectedPool?.token_id}
            </a>
          </Typography>
        </Box>
      );
    } else if (modalType === 'withdraw') {
      return (
        <Box>
          <Typography variant="h6" color="#FFFFFF">
            Withdrawing {selectedPool.symbol}
          </Typography>
          <Typography variant="body2" color="#A0A0A0" gutterBottom>
            Current balance: {parseFloat(selectedPool.balance).toLocaleString(undefined, { minimumFractionDigits: selectedPool.decimals })} {selectedPool.symbol}
          </Typography>
        <Box mt={2}>
          <TextFieldStyled
            label="WAX Wallet Address"
            variant="outlined"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
            error={walletAddress && walletAddress.length > 0 && !/^[a-z1-5.]{1,12}$/.test(walletAddress)}
            helperText={walletAddress && walletAddress.length > 0 && !/^[a-z1-5.]{1,12}$/.test(walletAddress) && 'Invalid WAX wallet address.'}
          />
        </Box>
        <Box mt={2}>
          <TextFieldStyled
            label="Amount"
            type="number"
            variant="outlined"
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(Math.max(0, Math.min(selectedPool.balance, e.target.value)))}
            InputProps={{ inputProps: { min: 0, max: selectedPool?.balance, step: Math.pow(10, -selectedPool.decimals) } }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" color="#FFFFFF" gutterBottom>
          Token Pools
        </Typography>
        <IconButton
          color="inherit"
          onClick={() => {
            fetchPools();
            fetchTotalPools();
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <Typography variant="body1" color="#A0A0A0" paragraph>
        Here you can manage your token pools. Toggle the active status to enable or disable a pool or use the icons to deposit or withdraw.
      </Typography>
      <TableContainerStyled component={Paper}>
        <TableStyled>
          <TableHead>
            <TableRowHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'token_id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'token_id'}
                  direction={orderBy === 'token_id' ? order : 'asc'}
                  onClick={() => handleRequestSort('token_id')}
                >
                  Pool ID
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'symbol' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'symbol'}
                  direction={orderBy === 'symbol' ? order : 'asc'}
                  onClick={() => handleRequestSort('symbol')}
                >
                  Token Symbol
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'contract' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'contract'}
                  direction={orderBy === 'contract' ? order : 'asc'}
                  onClick={() => handleRequestSort('contract')}
                >
                  Contract
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'balance' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'balance'}
                  direction={orderBy === 'balance' ? order : 'asc'}
                  onClick={() => handleRequestSort('balance')}
                >
                  Balance
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'rate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'rate'}
                  direction={orderBy === 'rate' ? order : 'asc'}
                  onClick={() => handleRequestSort('rate')}
                >
                  Rate
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled>
                Active
              </TableCellHeadStyled>
              <TableCellHeadStyled>
                Actions
              </TableCellHeadStyled>
            </TableRowHeadStyled>
          </TableHead>
          <TableBody>
            {pools.map((pool) => (
              <TableRowStyled key={pool.token_id}>
                <TableCellStyled>{pool.token_id}</TableCellStyled>
                <TableCellStyled>{pool.symbol}</TableCellStyled>
                <TableCellStyled>{pool.contract}</TableCellStyled>
                <TableCellStyled>{parseFloat(pool.balance).toLocaleString(undefined, { minimumFractionDigits: pool.decimals })}</TableCellStyled>
                <TableCellStyled
                  onClick={() => handleRateClick(pool.token_id, pool.rate)}
                  sx={{
                    cursor: 'pointer',
                    width: '80px',
                  }}
                >
                  {editRateId === pool.token_id ? (
                    <TextFieldStyled
                      value={editRateValue}
                      onChange={handleRateChange}
                      onBlur={() => handleRateBlur(pool)}
                      type="number"
                      autoFocus
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornmentStyled position="end">
                            <Typography>%</Typography>
                          </InputAdornmentStyled>
                        ),
                        style: {
                          padding: 0,
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                  ) : (
                    `${pool.rate}%`
                  )}
                </TableCellStyled>
                <TableCellStyled>
                  <Switch
                    checked={pool.is_active}
                    onChange={() => handleActiveChange(pool)}
                    sx={{
                      color: '#FFFFFF',
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#ff7e04',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 126, 4, 0.08)',
                        },
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#ff7e04',
                      },
                    }}
                  />
                </TableCellStyled>
                <TableCellStyled>
                  <Tooltip title="Deposit">
                    <IconButton onClick={() => handleDeposit(pool)} sx={{ color: 'green' }}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  </Tooltip>
                  {parseFloat(pool.balance) > 0 && (
                  <Tooltip title="Withdraw">
                    <IconButton onClick={() => handleWithdraw(pool)} sx={{ color: 'red' }}>
                      <ArrowUpwardIcon />
                    </IconButton>
                  </Tooltip>
                  )}
                </TableCellStyled>
              </TableRowStyled>
            ))}
          </TableBody>
        </TableStyled>
      </TableContainerStyled>
      <TablePaginationStyled
        component="div"
        count={totalPools}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
      <Modal
        open={open}
        onClose={handleClose}
        title={modalType === 'deposit' ? 'Deposit Tokens' : `Withdrawing ${selectedPool?.symbol}`}
        content={getModalContent()}
        onSubmit={modalType === 'withdraw' ? handleConfirmWithdraw : null}
        isProcessing={loading}
        isSuccess={isSuccess}
        isError={isError}
        message={message}
        onFinalClose={handleClose}
        hideSubmit={modalType === 'deposit'}
      />
    </Box>
  );
};

export default TokenPool;
