import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    session_key: null,
    role: null,
    twitch_id: null,
    isAuthenticated: false,
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/auth-callback') {
      // Check if the user is authenticated when the app loads
      axios.get('/api/auth/status', { withCredentials: true })
        .then(response => {
          if (response.data.isAuthenticated) {
            setAuthState({
              session_key: response.data.session_key,
              role: response.data.role,
              twitch_id: response.data.twitch_id,
              isAuthenticated: true,
            });
          } else {
            console.log('User is not authenticated:', response.data.message);
          }
        })
        .catch(error => {
          console.error('Error checking authentication status:', error);
        });
    }
  }, [location.pathname]);

  const login = (session_key, role, twitch_id) => {
    setAuthState({
      session_key: session_key,
      role: role,
      twitch_id: twitch_id,
      isAuthenticated: true,
    });

    console.log('Logged in:', { session_key, role, twitch_id });

    if (role === 'viewer') {
      navigate('/viewer-dashboard');
    } else {
      navigate('/streamer-dashboard');
    }
  };

  const logout = async () => {
    console.log('Logout function called');
    try {
      await axios.post('/api/logout', {}, { withCredentials: true });
      setAuthState({ session_key: null, role: null, twitch_id: null, isAuthenticated: false });
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
