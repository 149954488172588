import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, IconButton, Typography, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClipLoader from 'react-spinners/ClipLoader';

const TextFieldStyled = styled(TextField)({
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
  },
  '& .MuiInputLabel-root': {
    color: '#A0A0A0',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
    },
    '&:hover fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ff7e04',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ff7e04',
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  margin: 'normal',
  width: '100%',
});

const SelectStyled = styled(FormControl)({
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
  },
  '& .MuiInputLabel-root': {
    color: '#A0A0A0',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#FFFFFF',
    },
    '&:hover fieldset': {
      borderColor: '#FFFFFF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#ff7e04',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#ff7e04',
  },
  '& .MuiSvgIcon-root': {
    color: '#FFFFFF',
  },
  '& .MuiSelect-icon': {
    color: '#FFFFFF',
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px',
  },
  '& .MuiMenu-paper': {
    backgroundColor: '#060d1f',
    color: '#FFFFFF',
  },
  '& .MuiMenuItem-root': {
    '&:hover': {
      backgroundColor: '#ff7e04',
    },
  },
  margin: 'normal',
  width: '100%',
});

const ButtonStyled = styled(Button)(({ theme, disabled }) => ({
  backgroundColor: disabled ? '#D3D3D3' : '#ff7e04',
  color: disabled ? '#000000' : '#FFFFFF',
  '&:hover': {
    backgroundColor: disabled ? '#D3D3D3' : '#cc6503',
  },
}));

const DialogStyled = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: '#060d1f',
    color: '#FFFFFF',
    padding: '16px',
    width: '600px',
    maxWidth: '600px',
    height: 'auto',
    maxHeight: '90vh',
    borderRadius: '8px',
    position: 'relative',
  },
});

const DialogTitleStyled = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '16px',
  position: 'relative',
});

const CloseIconButton = styled(IconButton)({
  color: '#d60000',
  position: 'absolute',
  right: 8,
  top: 8,
  padding: '3px',
});

const DialogContentStyled = styled(DialogContent)({
  paddingTop: '8px',
});

const SuccessMessage = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '16px',
  color: '#4caf50',
});

// Components for different modal types
const CommandForm = ({ formData, setFormData }) => (
  <Box>
    <TextFieldStyled
      label="Command"
      variant="outlined"
      value={formData.command}
      onChange={(e) => setFormData({ ...formData, command: e.target.value })}
      fullWidth
      margin="normal"
      required
    />
    <TextFieldStyled
      label="Response"
      variant="outlined"
      value={formData.response}
      onChange={(e) => setFormData({ ...formData, response: e.target.value })}
      fullWidth
      margin="normal"
      required
    />
    <SelectStyled fullWidth margin="normal" required>
      <InputLabel>Mod Only</InputLabel>
      <Select
        value={formData.modOnly}
        onChange={(e) => setFormData({ ...formData, modOnly: e.target.value })}
        label="Mod Only"
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </Select>
    </SelectStyled>
  </Box>
);

const MessageForm = ({ formData, setFormData }) => (
  <Box>
    <TextFieldStyled
      label="Name"
      variant="outlined"
      value={formData.name}
      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
      fullWidth
      margin="normal"
      required
    />
    <TextFieldStyled
      label="Message"
      variant="outlined"
      value={formData.message}
      onChange={(e) => setFormData({ ...formData, message: e.target.value })}
      fullWidth
      margin="normal"
      required
    />
    <SelectStyled fullWidth margin="normal" required>
      <InputLabel>Interval (seconds)</InputLabel>
      <Select
        value={formData.interval}
        onChange={(e) => setFormData({ ...formData, interval: e.target.value })}
        label="Interval (seconds)"
      >
        <MenuItem value={15}>15</MenuItem>
        <MenuItem value={30}>30</MenuItem>
        <MenuItem value={60}>60</MenuItem>
      </Select>
    </SelectStyled>
  </Box>
);

const TokenForm = ({ amount, setAmount, maxAmount, walletAddress, setWalletAddress, memo, setMemo }) => (
  <Box>
    <TextFieldStyled
      label="Amount"
      variant="outlined"
      type="number"
      value={amount}
      onChange={(e) => setAmount(Math.max(1, Math.min(maxAmount, e.target.value)))}
      fullWidth
      margin="normal"
      required
      inputProps={{ min: 1, max: maxAmount }}
    />
    <TextFieldStyled
      label="WAX Wallet Address"
      variant="outlined"
      value={walletAddress}
      onChange={(e) => setWalletAddress(e.target.value)}
      fullWidth
      margin="normal"
      required
    />
    <TextFieldStyled
      label="Memo"
      variant="outlined"
      inputProps={{ maxLength: 100 }}
      value={memo}
      onChange={(e) => setMemo(e.target.value)}
      fullWidth
      margin="normal"
    />
  </Box>
);

const NftForm = ({ walletAddress, setWalletAddress, selectedAssets }) => (
  <Box>
    <Typography>
      {`You are about to withdraw ${selectedAssets.length} asset${selectedAssets.length > 1 ? 's' : ''}.`}
    </Typography>
    <TextFieldStyled
      label="WAX Wallet Address"
      variant="outlined"
      value={walletAddress}
      onChange={(e) => setWalletAddress(e.target.value)}
      fullWidth
      margin="normal"
      required
    />
  </Box>
);

const getFormComponent = (modalType, props) => {
  switch (modalType) {
    case 'command':
      return <CommandForm {...props} />;
    case 'message':
      return <MessageForm {...props} />;
    case 'token':
      return <TokenForm {...props} />;
    case 'nft':
      return <NftForm {...props} />;
    default:
      return null;
  }
};

const getConfirmationMessage = (confirmation) => {
  switch (confirmation?.type) {
    case 'command':
      return `Command added successfully\n\nCommand: ${confirmation.command}\nResponse: ${confirmation.response}\nMod Only: ${confirmation.modOnly}`;
    case 'message':
      return `Timed Message added successfully\n\nName: ${confirmation.name}\nMessage: ${confirmation.message}\nInterval: ${confirmation.interval} seconds`;
    case 'token':
      return `${confirmation.amount} ${confirmation.token} was withdrawn to ${confirmation.walletAddress}. Please see transaction [here](#).`;
    case 'nft':
      return `Assets withdrawn to ${confirmation.walletAddress}:\n${confirmation.selectedAssets.length} asset(s)`;
    default:
      return 'Withdrawal Confirmed';
  }
};

const ModalForm = ({
  open,
  onClose,
  title,
  onConfirm,
  modalType,
  formData,
  setFormData,
  amount,
  setAmount,
  maxAmount,
  walletAddress,
  setWalletAddress,
  memo,
  setMemo,
  selectedPool,
  selectedAssets = [],
  isToken = true,
  isDeposit = false,
  onFetchData,
  loading,
  content,
  setLoading,
}) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [transferResult, setTransferResult] = useState(null);

  useEffect(() => {
    if (open) {
      setConfirmation(null);
      setTransferResult(null);
    }
  }, [open]);

  const handleConfirm = async () => {
    const confirmationData = {
      type: modalType,
      ...(modalType === 'token' && { token: selectedPool?.token, amount, walletAddress }),
      ...(modalType === 'command' && { command: formData.command, response: formData.response, modOnly: formData.modOnly }),
      ...(modalType === 'message' && { name: formData.name, message: formData.message, interval: formData.interval }),
      ...(modalType === 'nft' && { walletAddress, selectedAssets }),
    };

    setConfirmation(confirmationData);
  console.log('Confirmation Data:', confirmationData);  // Added logging
  
    try {
      if (modalType === 'nft') {
        setLoading(true);
        const result = await onConfirm();
        console.log('NFT transfer result:', result);
        setTransferResult(result);
        setLoading(false);
      }
      setConfirmationOpen(true);
      if (onFetchData) {
        onFetchData();
      }
    } catch (error) {
      console.error(`Error during ${modalType} transfer:`, error);
      setLoading(false);
    }

    onClose();
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const isConfirmDisabled = () => {
    switch (modalType) {
      case 'command':
        return !formData.command || !formData.response || formData.modOnly === '';
      case 'message':
        return !formData.name || !formData.message || !formData.interval;
      case 'token':
        return !walletAddress || !amount || amount < 1 || amount > maxAmount;
      case 'nft':
        return !walletAddress || !selectedAssets.length;
      default:
        return false;
    }
  };

  const getNftTransferMessage = () => {
    if (transferResult) {
      return (
        <>
          <SuccessMessage>
            <CheckCircleIcon style={{ color: '#4caf50', marginRight: '8px' }} />
            <Typography>Transaction success</Typography>
          </SuccessMessage>
          {transferResult.map((result, index) => (
            <Box key={index} mb={2}>
              <Typography>Transaction ID: <a href={`https://waxblock.io/transaction/${result.trx_id}`} target="_blank" rel="noopener noreferrer">{result.trx_id}</a></Typography>
            </Box>
          ))}
        </>
      );
    }
    return null;
  };

  const formProps = {
    formData,
    setFormData,
    amount,
    setAmount,
    maxAmount,
    walletAddress,
    setWalletAddress,
    memo,
    setMemo,
    selectedAssets,
  };

  return (
    <>
      <DialogStyled open={open} onClose={onClose}>
        <DialogTitleStyled>
          {title}
          <CloseIconButton onClick={onClose}>
            <CloseIcon />
          </CloseIconButton>
        </DialogTitleStyled>
        <DialogContentStyled>
          {loading ? (
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
              <ClipLoader size={50} color="#ff7e04" loading={true} />
              <Typography>Processing NFT transfer...</Typography>
            </Box>
          ) : isDeposit ? (
            <Box>
              <Typography>
                To deposit tokens, please send them to the following wallet address with the specified memo:
              </Typography>
              <Typography variant="h6" color="#ff7e04">
                Wallet Address: YOUR_WALLET_ADDRESS
              </Typography>
              <Typography variant="h6" color="#ff7e04">
                Memo: SPECIFIC_MEMO
              </Typography>
            </Box>
          ) : (
            getFormComponent(modalType, formProps) || <Box>{content}</Box>
          )}
        </DialogContentStyled>
        {!isDeposit && !loading && modalType !== 'createtoken' && (
          <DialogActions>
            <ButtonStyled onClick={handleConfirm} disabled={isConfirmDisabled()}>
              Confirm
            </ButtonStyled>
          </DialogActions>
        )}
      </DialogStyled>

      <DialogStyled open={confirmationOpen} onClose={handleCloseConfirmation}>
        <DialogTitleStyled>
          {confirmation?.type === 'command' ? 'Command Added' :
            confirmation?.type === 'message' ? 'Timed Message Added' :
              confirmation?.type === 'token' ? 'Token Withdrawal Confirmed' :
                'NFT Withdrawal Confirmed'}
          <CloseIconButton onClick={handleCloseConfirmation}>
            <CloseIcon />
          </CloseIconButton>
        </DialogTitleStyled>
        <DialogContentStyled>
          {modalType === 'nft' ? getNftTransferMessage() : (
            <Typography>
              {getConfirmationMessage(confirmation)}
            </Typography>
          )}
        </DialogContentStyled>
      </DialogStyled>
    </>
  );
};

export default ModalForm;