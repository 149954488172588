import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

const ListItemStyled = styled(ListItem)(({ selected, theme }) => ({
  '& .MuiListItemText-root': {
    fontWeight: selected ? 'bold' : 'normal',
    color: selected ? '#ff7e04' : '#FFFFFF',
  },
  '&:hover': {
    backgroundColor: (theme.palette.action && theme.palette.action.hover) || '#333333', // Fallback color if theme.palette.action.hover is undefined
    '& .MuiListItemText-root': {
      fontWeight: 'bold',
      transition: 'font-weight 0.3s ease',
    },
  },
}));

const Menu = ({ items }) => {
  const theme = useTheme();
  const location = useLocation();
  const [openSubItems, setOpenSubItems] = useState({});

  useEffect(() => {
    const initialOpenState = {};
    items.forEach(item => {
      if (item.subItems) {
        item.subItems.forEach(subItem => {
          if (subItem.link === location.pathname) {
            initialOpenState[item.label] = true;
          }
        });
      }
    });
    setOpenSubItems(initialOpenState);
  }, [items, location.pathname]);

  const handleClick = (label) => {
    setOpenSubItems((prevState) => ({ ...prevState, [label]: !prevState[label] }));
  };

  const renderSubItems = (subItems, parentLabel) => {
    return subItems.map((subItem) => (
      <ListItemStyled
        key={subItem.label}
        button
        component={Link}
        to={subItem.link}
        style={{ paddingLeft: 20 }}
        selected={location.pathname === subItem.link}
        theme={theme}
      >
        <ListItemText primary={subItem.label} />
      </ListItemStyled>
    ));
  };

  return (
    <List component="nav" style={{ color: '#FFFFFF', paddingTop: 0 }}>
      {items.map((item) => (
        <React.Fragment key={item.label}>
          {item.subItems ? (
            <>
              <ListItemStyled
                button
                onClick={() => handleClick(item.label)}
                theme={theme}
              >
                <ListItemText primary={item.label} />
                {openSubItems[item.label] ? <ExpandLess /> : <ExpandMore />}
              </ListItemStyled>
              <Collapse in={openSubItems[item.label]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderSubItems(item.subItems, item.label)}
                </List>
              </Collapse>
            </>
          ) : (
            <ListItemStyled
              button
              component={Link}
              to={item.link}
              selected={location.pathname === item.link}
              theme={theme}
            >
              <ListItemText primary={item.label} />
            </ListItemStyled>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default Menu;
