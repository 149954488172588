import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledContainer = styled(Container)({
  padding: '16px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  boxSizing: 'border-box',
  minHeight: '100vh',
  position: 'relative',
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const FeatureCommands = () => {
  return (
    <StyledContainer>
      <Header>
        <Typography variant="h4" gutterBottom>
          Commands
        </Typography>
        <IconButton component={Link} to="/features" sx={{ color: '#FFFFFF' }}>
          <ArrowBackIcon />
        </IconButton>
      </Header>
      <Typography variant="body1" gutterBottom>
        Common and custom commands to help manage and bring fun to your channel. Commands are typically used by streamers to enhance viewer interaction and manage their broadcast effectively. Here are some common uses for streamer commands:
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Chat Interaction:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Chat Moderation</strong>: Commands to ban users, timeout users, delete messages, or manage chat settings.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Chat Games</strong>: Commands for chat-based games, polls, or interactive activities.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Chat Alerts</strong>: Commands to trigger alerts for new followers, subscribers, donations, etc.
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        2. Viewer Engagement:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Interactive Commands</strong>: Commands that viewers can use to trigger effects or interact with overlays (e.g., displaying viewer points, triggering sound effects).
        </Typography>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Giveaways</strong>: Commands to start, manage, and announce winners of giveaways.
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        3. Data Display:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Stats and Info</strong>: Commands to display current game stats, stream uptime, viewer count, etc.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Custom Commands</strong>: Streamers often create custom commands to provide information about themselves, their schedule, social media links, etc.
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        4. Automation and Integration:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          ○ <strong>API Integrations</strong>: Advanced streamers may use commands that integrate with external APIs to fetch data (e.g., weather, stock prices, latest news) or control external devices.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Donation commands</strong>: One of Scurvy Bot’s unique features is being able to set commands for crypto donations, with fiat coming in future updates. At first it will be $WAXP tracking with more crypto options coming. This is useful for allowing users to donate crypto and trigger commands. This could be as simple as a chat command, to triggering animations or overlays. This brings a whole new revenue avenue for streamers to play with and explore.
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        5. Channel Growth and Interaction:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Promotion</strong>: Commands to promote social media channels, Discord servers, or other platforms.
        </Typography>
        <Typography variant="body1" gutterBottom>
          ○ <strong>Feedback and Interaction</strong>: Commands to gather feedback from viewers, conduct Q&A sessions, or encourage viewer-submitted content.
        </Typography>
      </Box>
      <Typography variant="body1" gutterBottom>
        Overall, streamer commands are versatile tools that help streamline the streaming experience, enhance viewer engagement, and manage the community effectively. They vary widely in complexity and functionality depending on the needs and preferences of the streamer.
      </Typography>
    </StyledContainer>
  );
};

export default FeatureCommands;
