import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Checkbox, Button
} from '@mui/material';
import { styled } from '@mui/system';
import ModalForm from './ModalForm';

const TableContainerStyled = styled(TableContainer)({
  backgroundColor: '#32384B',
  color: '#FFFFFF',
  border: '1.5px solid #FFFFFF',
  borderRadius: '8px',
  overflow: 'hidden',
});

const TableCellStyled = styled(TableCell)({
  border: '1px solid #D3D3D3',
  color: '#FFFFFF',
});

const TableCellHeadStyled = styled(TableCell)({
  backgroundColor: '#060d1f',
  color: '#FFFFFF',
  fontWeight: 'bold',
  border: '1.5px solid #FFFFFF',
  borderBottom: 'none',
  '& .MuiTableSortLabel-root': {
    color: '#FFFFFF !important',
    '&.MuiTableSortLabel-active': {
      color: '#FFFFFF',
    },
    '& .MuiTableSortLabel-icon': {
      color: '#FFFFFF !important',
    },
  },
});

const TableRowHeadStyled = styled(TableRow)({
  '& > th:first-of-type': {
    borderTopLeftRadius: '8px',
  },
  '& > th:last-of-type': {
    borderTopRightRadius: '8px',
  },
});

const TableRowStyled = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#3b3f52',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#2d3143',
  },
});

const ButtonStyled = styled(Button)({
  backgroundColor: '#ff7e04',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#cc6503',
  },
});

const TimedMessages = () => {
  const [messages, setMessages] = useState([
    { name: 'Promo1', message: 'Check out our latest merch!', interval: 15, active: true },
    { name: 'Promo2', message: 'Follow us on Twitter!', interval: 30, active: true },
    { name: 'Promo3', message: 'Subscribe to our channel!', interval: 60, active: false },
  ]);

  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', message: '', interval: 15 });

  const handleActiveChange = (index) => {
    setMessages(prevMessages =>
      prevMessages.map((message, i) =>
        i === index ? { ...message, active: !message.active } : message
      )
    );
  };

  const handleAddMessage = () => {
    setFormData({ name: '', message: '', interval: 15 });
    setModalOpen(true);
  };

  const handleConfirm = () => {
    setMessages(prevMessages => [...prevMessages, formData]);
    setModalOpen(false);
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" color="#FFFFFF">
          Timed Messages
        </Typography>
        <ButtonStyled variant="contained" onClick={handleAddMessage}>
          Add New Message
        </ButtonStyled>
      </Box>
      <Typography variant="body1" color="#A0A0A0" paragraph>
        Manage the timed messages that will be displayed on the stream.
      </Typography>
      <TableContainerStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRowHeadStyled>
              <TableCellHeadStyled>Name</TableCellHeadStyled>
              <TableCellHeadStyled>Message</TableCellHeadStyled>
              <TableCellHeadStyled>Interval (seconds)</TableCellHeadStyled>
              <TableCellHeadStyled>Active</TableCellHeadStyled>
            </TableRowHeadStyled>
          </TableHead>
          <TableBody>
            {messages.map((message, index) => (
              <TableRowStyled key={index}>
                <TableCellStyled>{message.name}</TableCellStyled>
                <TableCellStyled>{message.message}</TableCellStyled>
                <TableCellStyled>{message.interval}</TableCellStyled>
                <TableCellStyled>
                  <Checkbox
                    checked={message.active}
                    onChange={() => handleActiveChange(index)}
                    sx={{
                      color: '#FFFFFF',
                      '&.Mui-checked': {
                        color: '#ff7e04',
                      },
                    }}
                  />
                </TableCellStyled>
              </TableRowStyled>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <ModalForm
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Add New Message"
        onConfirm={handleConfirm}
        modalType="message"
        formData={formData}
        setFormData={setFormData}
      />
    </Box>
  );
};

export default TimedMessages;
