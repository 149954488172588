import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper, Typography, Box, TableSortLabel, MenuItem, IconButton, TextField, Select, Switch, Button
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Modal from './Modal';

const permissionLevels = {
  Everyone: 0,
  Subscriber: 1,
  VIP: 2,
  Moderator: 3,
  Broadcaster: 4,
};

const Commands = () => {
  const [commands, setCommands] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [commandToDelete, setCommandToDelete] = useState(null);
  const [editCommandValues, setEditCommandValues] = useState({
    id: null,
    command: '',
    response: '',
    is_active: '',
    permission_level: ''
  });
  const [newCommandValues, setNewCommandValues] = useState({
    command: '',
    response: '',
    is_active: true,
    permission_level: permissionLevels.Everyone
  });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchCommands();
  }, []);

  const fetchCommands = async () => {
    try {
      const response = await axios.get('/api/commands');
      setCommands(response.data.data);
    } catch (error) {
      console.error('Error fetching commands:', error);
    }
  };

  const openModal = (command) => {
    setEditCommandValues({
      id: command.id,
      command: command.command,
      response: command.response,
      is_active: command.is_active,
      permission_level: command.permission_level
    });
    setIsModalOpen(true);
  };

  const openDeleteModal = (command) => {
    setCommandToDelete(command);
    setIsDeleteModalOpen(true);
  };

  const openAddModal = () => {
    setNewCommandValues({
      command: '',
      response: '',
      is_active: true,
      permission_level: permissionLevels.Everyone
    });
    setIsAddModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
    setIsAddModalOpen(false);
    setErrorMessage('');
  };

  const handleCommandChange = (field, isEdit = true) => (event) => {
    const value = event.target.value;

    if (isEdit) {
      setEditCommandValues({
        ...editCommandValues,
        [field]: value
      });
    } else {
      setNewCommandValues({
        ...newCommandValues,
        [field]: value
      });
    }
  };

  const validateCommand = async (command) => {
    if (!command.startsWith('!') || command.length > 25) {
      setErrorMessage('Command must start with "!" and be 25 characters or less.');
      return false;
    }

    // Check if the command already exists
    const existingCommand = commands.find(cmd => cmd.command === command);
    if (existingCommand) {
      setErrorMessage('Command name already exists.');
      return false;
    }

    // Potentially, send a request to check for built-in commands here
    // Example:
    // const response = await axios.post('/api/commands/check', { command });
    // if (!response.data.success) {
    //   setErrorMessage(response.data.message);
    //   return false;
    // }

    return true;
  };

  const handleSave = async () => {
    if (!await validateCommand(editCommandValues.command)) return;

    try {
      await axios.post('/api/commands/update', {
        id: editCommandValues.id,
        command: editCommandValues.command,
        response: editCommandValues.response,
        is_active: editCommandValues.is_active,
        permission_level: editCommandValues.permission_level
      });
      setCommands((prevCommands) =>
        prevCommands.map((cmd) =>
          cmd.id === editCommandValues.id
            ? { ...cmd, ...editCommandValues }
            : cmd
        )
      );
      closeModal();
    } catch (error) {
      console.error('Error updating command:', error);
    }
  };

  const handleAdd = async () => {
    if (!await validateCommand(newCommandValues.command)) return;

    try {
      await axios.post('/api/commands/add', {
        command: newCommandValues.command,
        response: newCommandValues.response,
        is_active: newCommandValues.is_active,
        permission_level: newCommandValues.permission_level
      });
      fetchCommands(); // Refresh the command list after adding
      closeModal();
    } catch (error) {
      console.error('Error adding command:', error);
    }
  };

  const handleDelete = async () => {
    if (!commandToDelete) return;

    try {
      await axios.post('/api/commands/delete', { id: commandToDelete.id });
      setCommands((prevCommands) => prevCommands.filter((cmd) => cmd.id !== commandToDelete.id));
      closeModal();
    } catch (error) {
      console.error('Error deleting command:', error);
    }
  };

  const handleActiveChange = async (command) => {
    if (command.command_type !== 'custom') return;

    try {
      const updatedCommand = { ...command, is_active: !command.is_active };
      await axios.post('/api/commands/update', {
        id: command.id,
        command: command.command,
        response: command.response,
        is_active: updatedCommand.is_active,
        permission_level: command.permission_level,
      });
      setCommands((prevCommands) =>
        prevCommands.map((cmd) =>
          cmd.id === command.id ? updatedCommand : cmd
        )
      );
    } catch (error) {
      console.error('Error updating active status:', error);
    }
  };

  const getPermissionLevelKey = (value) => {
    return Object.keys(permissionLevels).find(key => permissionLevels[key] === value);
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Typography variant="h5" gutterBottom>
          Commands
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={openAddModal}
          sx={{ color: '#FFFFFF' }}
        >
          ADD COMMAND
        </Button>
      </Box>
      <Typography variant="body1" paragraph>
        Manage your commands here. You can edit the command name, response, permission level, or toggle the active status.
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection="asc">
                <TableSortLabel active direction="asc">Command</TableSortLabel>
              </TableCell>
              <TableCell>Response</TableCell>
              <TableCell>Permission Level</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commands.map((command) => (
              <TableRow key={command.id}>
                <TableCell>{command.command}</TableCell>
                <TableCell>{command.response}</TableCell>
                <TableCell>{getPermissionLevelKey(command.permission_level)}</TableCell>
                <TableCell>
                  <Switch
                    checked={command.is_active}
                    onChange={() => handleActiveChange(command)}
                    disabled={command.command_type !== 'custom'}
                  />
                </TableCell>
                <TableCell>
                  {command.command_type === 'custom' && (
                    <>
                      <IconButton onClick={() => openModal(command)}>
                        <EditIcon sx={{ color: 'secondary.main' }} />
                      </IconButton>
                      <IconButton onClick={() => openDeleteModal(command)}>
                        <DeleteIcon sx={{ color: 'error.main' }} />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Adding a New Command */}
      <Modal
        open={isAddModalOpen}
        onClose={closeModal}
        title="Add Command"
        content={
          <Box>
            <TextField
              label="Command"
              value={newCommandValues.command}
              onChange={handleCommandChange('command', false)}
              fullWidth
              margin="normal"
              helperText={errorMessage}
              error={Boolean(errorMessage)}
            />
            <TextField
              label="Response"
              value={newCommandValues.response}
              onChange={handleCommandChange('response', false)}
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 500 }}
              helperText={
                <Typography variant="body2" color={newCommandValues.response.length === 500 ? '#ff0000' : newCommandValues.response.length > 450 ? '#ff7e04' : '#ffffff'}>
                  {`${newCommandValues.response.length}/500 characters`}
                </Typography>
              }
              multiline
              minRows={6}
              maxRows={10}
            />
            <Box sx={{ marginBottom: '12px' }} />
            <Select
              label="Permission Level"
              InputLabelProps={{
                shrink: true,
              }}
              value={newCommandValues.permission_level}
              onChange={handleCommandChange('permission_level', false)}
              fullWidth
              margin="normal"
            >
              {Object.entries(permissionLevels).map(([key, value]) => (
                <MenuItem key={value} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
        onSubmit={handleAdd}
      />

      {/* Modal for Editing */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        title="Edit Command"
        content={
          <Box>
            <TextField
              label="Command"
              value={editCommandValues.command}
              onChange={handleCommandChange('command')}
              fullWidth
              margin="normal"
              helperText={errorMessage}
              error={Boolean(errorMessage)}
            />
            <TextField
              label="Response"
              value={editCommandValues.response}
              onChange={handleCommandChange('response')}
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 500 }}
              helperText={
                <Typography variant="body2" color={editCommandValues.response.length === 500 ? '#ff0000' : editCommandValues.response.length > 450 ? '#ff7e04' : '#ffffff'}>
                  {`${editCommandValues.response.length}/500 characters`}
                </Typography>
              }
              multiline
              minRows={6}
              maxRows={10}
            />
            <Box sx={{ marginBottom: '12px' }} />
            <Select
              label="Permission Level"
              InputLabelProps={{
                shrink: true,
              }}
              value={editCommandValues.permission_level}
              onChange={handleCommandChange('permission_level')}
              fullWidth
              margin="normal"
            >
              {Object.entries(permissionLevels).map(([key, value]) => (
                <MenuItem key={value} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </Box>
        }
        onSubmit={handleSave}
      />

      {/* Modal for Delete Confirmation */}
      <Modal
        open={isDeleteModalOpen}
        onClose={closeModal}
        title="Confirm Delete"
        content={
          <Box>
            <Typography variant="body1">
              Are you sure you want to delete the command "{commandToDelete?.command}"?
            </Typography>
          </Box>
        }
        onSubmit={handleDelete}
      />
    </Box>
  );
};

export default Commands;
