import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledContainer = styled(Container)({
  padding: '16px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  boxSizing: 'border-box',
  minHeight: '100vh',
  position: 'relative',
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ShoutoutOverlay = () => {
  return (
    <StyledContainer>
      <Header>
        <Typography variant="h4" gutterBottom>
          Shoutout Overlay
        </Typography>
        <IconButton component={Link} to="/features" sx={{ color: '#FFFFFF' }}>
          <ArrowBackIcon />
        </IconButton>
      </Header>
      <Typography variant="body1" gutterBottom>
        Shout your friends out in style while displaying their info and even clips on stream! Use Scurvy Bot’s shoutout overlay to bring some hype when it's time to show some love to your friends on air. Some of the features and customization include:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          1. <strong>Default Chat Response:</strong> Have a default chat response that shows the viewer's name and clickable link for others to follow their channel. Customize that message to fit your channel’s brand and vibe.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. <strong>On-Screen Overlay:</strong> Choose to show the viewer's information such as channel profile picture, channel link, and last playing, and bring in a random clip to play from the viewer's channel. This brings a very fun and interactive way to promote your friends and bring them into your show.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. <strong>Overlay Background Control:</strong> Control the background color and opacity.
        </Typography>
        <Typography variant="body1" gutterBottom>
          4. <strong>Clip Maximum Length:</strong> Set the maximum length of the clip to be aired.
        </Typography>
        <Typography variant="body1" gutterBottom>
          5. <strong>Clip Selection:</strong> Option of displaying a truly random clip or one of their top 10 clips.
        </Typography>
      </Box>
      <Typography variant="body1" gutterBottom>
        With the options Scurvy Bot’s shoutout overlay provides, you can truly make your friends feel special while incorporating your theme as you show them love. A fun way to bring your community to them during the show without skipping a beat.
      </Typography>
    </StyledContainer>
  );
};

export default ShoutoutOverlay;
