import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Typography, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

const validationSchema = yup.object({
  tokenName: yup.string('Enter a token name').max(35, 'Token name must be less than 35 characters').required('Token name is required'),
  viewingTokens: yup.number('Enter tokens earned per 10 minutes of viewing')
    .test('is-decimal', 'Must be a number with up to 2 decimal places', value => (value !== undefined && /^-?\d+(\.\d{1,2})?$/.test(value)))
    .min(0, 'Must be greater than or equal to 0')
    .required('Tokens earned per 10 minutes of viewing is required'),
  subscriptionTokens: yup.number('Enter tokens earned per subscription')
    .test('is-decimal', 'Must be a number with up to 2 decimal places', value => (value !== undefined && /^-?\d+(\.\d{1,2})?$/.test(value)))
    .min(0, 'Must be greater than or equal to 0')
    .required('Tokens earned per subscription is required'),
  bitsTokens: yup.number('Enter tokens earned per 100 bits')
    .test('is-decimal', 'Must be a number with up to 2 decimal places', value => (value !== undefined && /^-?\d+(\.\d{1,2})?$/.test(value)))
    .min(0, 'Must be greater than or equal to 0')
    .required('Tokens earned per 100 bits is required'),
  subscriberBonus: yup.number('Enter subscriber bonus earnings')
    .test('is-decimal', 'Must be a number with up to 2 decimal places', value => (value !== undefined && /^-?\d+(\.\d{1,2})?$/.test(value)))
    .min(0, 'Must be greater than or equal to 0')
    .required('Subscriber bonus earnings is required'),
  chatterBonus: yup.number('Enter chatter bonus earnings')
    .test('is-decimal', 'Must be a number with up to 2 decimal places', value => (value !== undefined && /^-?\d+(\.\d{1,2})?$/.test(value)))
    .min(0, 'Must be greater than or equal to 0')
    .required('Chatter bonus earnings is required'),
});

const ChannelTokenForm = () => {
  const [initialValues, setInitialValues] = useState({
    tokenName: '',
    viewingTokens: '',
    subscriptionTokens: '',
    bitsTokens: '',
    subscriberBonus: '',
    chatterBonus: '',
  });
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const fetchTokenConfig = async () => {
    try {
      const response = await axios.get('/api/channel-token', {
        withCredentials: true,
      });

      if (response.data.success) {
        setInitialValues({
          tokenName: response.data.data.token_name,
          viewingTokens: response.data.data.viewing_tokens.toFixed(2),
          subscriptionTokens: response.data.data.subscription_tokens.toFixed(2),
          bitsTokens: response.data.data.bits_tokens.toFixed(2),
          subscriberBonus: response.data.data.subscriber_bonus.toFixed(2),
          chatterBonus: response.data.data.chatter_bonus.toFixed(2),
        });
      }
    } catch (error) {
      console.error('Error fetching token config:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenConfig();
  }, []);

  const handleNumberChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleNumberBlur = (event) => {
    const { name, value } = event.target;
    const floatValue = parseFloat(value);
    if (!isNaN(floatValue)) {
      formik.setFieldValue(name, floatValue.toFixed(2));
    } else {
      formik.setFieldValue(name, '');
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post('/api/channel-token', values, {
          withCredentials: true,
        });
        setShowConfirmation(true);
        setTimeout(() => setShowConfirmation(false), 3000);
        fetchTokenConfig();
      } catch (error) {
        console.error('Error updating token config:', error);
      }
    },
  });

  const generateLabel = (baseLabel) => {
    const tokenName = formik.values.tokenName || 'Token';
    return `${tokenName} ${baseLabel}`;
  };

  const isSubmitDisabled = JSON.stringify(formik.values) === JSON.stringify(initialValues);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      autoComplete="off"
      sx={{ padding: '16px' }}
    >
      <Typography variant="h5" gutterBottom>
        Channel Token Configuration
      </Typography>
      <Typography variant="body1" paragraph>
        Configure the settings for channel tokens below. Set the values for token earnings and bonuses. Viewers must type !earn in chat first to start receiving loyalty tokens.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="tokenName"
            name="tokenName"
            label="Token Name"
            value={formik.values.tokenName}
            onChange={formik.handleChange}
            error={formik.touched.tokenName && Boolean(formik.errors.tokenName)}
            helperText={formik.touched.tokenName && formik.errors.tokenName}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            id="viewingTokens"
            name="viewingTokens"
            label={generateLabel('earned for viewers (per 10 minutes)')}
            value={formik.values.viewingTokens}
            onChange={handleNumberChange}
            onBlur={handleNumberBlur}
            error={formik.touched.viewingTokens && Boolean(formik.errors.viewingTokens)}
            helperText={formik.touched.viewingTokens && formik.errors.viewingTokens}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            id="subscriberBonus"
            name="subscriberBonus"
            label={generateLabel('bonus earned for subscribers (per 10 minutes)')}
            value={formik.values.subscriberBonus}
            onChange={handleNumberChange}
            onBlur={handleNumberBlur}
            error={formik.touched.subscriberBonus && Boolean(formik.errors.subscriberBonus)}
            helperText={formik.touched.subscriberBonus && formik.errors.subscriberBonus}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            id="chatterBonus"
            name="chatterBonus"
            label={generateLabel('bonus earned for chatters (per 10 minutes)')}
            value={formik.values.chatterBonus}
            onChange={handleNumberChange}
            onBlur={handleNumberBlur}
            error={formik.touched.chatterBonus && Boolean(formik.errors.chatterBonus)}
            helperText={formik.touched.chatterBonus && formik.errors.chatterBonus}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            id="subscriptionTokens"
            name="subscriptionTokens"
            label={generateLabel('earned per subscription')}
            value={formik.values.subscriptionTokens}
            onChange={handleNumberChange}
            onBlur={handleNumberBlur}
            error={formik.touched.subscriptionTokens && Boolean(formik.errors.subscriptionTokens)}
            helperText={formik.touched.subscriptionTokens && formik.errors.subscriptionTokens}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            id="bitsTokens"
            name="bitsTokens"
            label={generateLabel('earned per 100 bits')}
            value={formik.values.bitsTokens}
            onChange={handleNumberChange}
            onBlur={handleNumberBlur}
            error={formik.touched.bitsTokens && Boolean(formik.errors.bitsTokens)}
            helperText={formik.touched.bitsTokens && formik.errors.bitsTokens}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" type="submit" disabled={isSubmitDisabled} fullWidth>
            Submit
          </Button>
        </Grid>
        {showConfirmation && (
          <Grid item xs={12}>
            <Typography variant="body2" color="text.primary">
              Changes have been saved!
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ChannelTokenForm;
