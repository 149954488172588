import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import Autocomplete from '@mui/lab/Autocomplete';
import ModalForm from './ModalForm';
import ClipLoader from 'react-spinners/ClipLoader';

const validationSchema = yup.object({
  symbol: yup.object().nullable().required('Symbol is required'),
  redemptionRate: yup
    .number('Enter a redemption rate')
    .min(0, 'Redemption rate must be at least 0%')
    .integer('Redemption rate must be an integer')
    .required('Redemption rate is required'),
});

const CreatePool = () => {
  const [symbols, setSymbols] = useState([]);
  const [tokenName, setTokenName] = useState('Loyalty');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        const response = await axios.get('/api/tokens');
        const symbolOptions = response.data.map((token) => ({
          value: token.token_id,
          label: token.symbol + ' (' + token.contract + ')',
          decimals: token.decimals,
        }));
        symbolOptions.sort((a, b) => a.label.localeCompare(b.label));
        setSymbols(symbolOptions);
      } catch (error) {
        console.error('Error fetching symbols:', error);
      }
    };

    const fetchTokenName = async () => {
      try {
        const response = await axios.get('/api/channel-token');
        if (response.data.success) {
          setTokenName(response.data.data.token_name);
        }
      } catch (error) {
        console.error('Error fetching channel token:', error);
      }
    };

    fetchSymbols();
    fetchTokenName();
  }, []);

  const formik = useFormik({
    initialValues: {
      symbol: null,
      redemptionRate: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      setModalOpen(true);
      setModalTitle('Creating Pool');
      setModalContent(
        <Box>
          <ClipLoader size={50} />
          <Typography>Creating pool...</Typography>
        </Box>
      );
      try {
        const response = await axios.post('/api/token-pool', {
          token_id: values.symbol.value,
          rate: values.redemptionRate,
          balance: 0,
          is_active: false,
        }, {
          withCredentials: true
        });
        setLoading(false);
        if (response.data.success) {
          setModalTitle('Success');
          setModalContent(
            <>
              <Typography variant="h6">
                {values.symbol.label.split(' ')[0]} pool set up successfully.
              </Typography>
              <Typography variant="body1">
                To make it active, please view all your token pools <a href="/streamer-dashboard/token-pools">here, set the pool to active and deposit tokens using the link</a>.
              </Typography>
            </>
          );
        } else {
          setModalTitle('Error');
          setModalContent(
            <>
              <Typography variant="h6" color="error">
                {values.symbol.label.split(' ')[0]} pool already exists.
              </Typography>
              <Typography variant="body1">
                View all your token pools <a href="/streamer-dashboard/token-pools">here</a>.
              </Typography>
            </>
          );
        }
        resetForm();
      } catch (error) {
        setLoading(false);
        setModalTitle('Error');
        setModalContent(
          <>
            <Typography variant="h6" color="error">
              Error setting up {values.symbol.label.split(' ')[0]} pool.
            </Typography>
            <Typography variant="body1">
              Please try again later. View all your token pools <a href="/streamer-dashboard/token-pools">here</a>.
            </Typography>
          </>
        );
      }
    },
  });

  const handleSymbolChange = (event, value) => {
    formik.setFieldValue('symbol', value);
  };

  const calculateTokensReceived = (rate) => {
    return (rate / 100) * 100; // Calculation based on 100 loyalty points
  };

  const formatNumber = (num) => {
    return num.toLocaleString();
  };

  const handleClose = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  return (
    <>
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Typography variant="h5" gutterBottom>
        Create a New Pool
      </Typography>
      <Typography variant="body1" paragraph>
        Configure a new token Pool for your viewers to redeem their loyalty tokens for.
      </Typography>
      <Box mb={3} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            options={symbols}
            getOptionLabel={(option) => option.label}
            value={formik.values.symbol}
            onChange={handleSymbolChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                id="symbol"
                name="symbol"
                label="Symbol (Contract)"
                variant="outlined"
                required
                error={formik.touched.symbol && Boolean(formik.errors.symbol)}
                helperText={formik.touched.symbol && formik.errors.symbol}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="redemptionRate"
            name="redemptionRate"
            label="Redemption Rate (%)"
            type="number"
            value={formik.values.redemptionRate}
            onChange={formik.handleChange}
            error={formik.touched.redemptionRate && Boolean(formik.errors.redemptionRate)}
            helperText={formik.touched.redemptionRate && formik.errors.redemptionRate}
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {formik.values.symbol && (
        <Grid item xs={12}>
          <Typography variant="body2">
          Viewers will receive {formatNumber(calculateTokensReceived(formik.values.redemptionRate))} {formik.values.symbol.label.split(' ')[0]} per 100 {tokenName} redeemed.
          </Typography> 
        </Grid>
        )}
        <Grid item xs={12}>
          <Button variant="contained" type="submit">
            Create Pool
          </Button>
        </Grid>
      </Grid>
    </form>
      <ModalForm
        open={modalOpen}
        onClose={handleClose}
        title={modalTitle}
        modalType="createtoken"
        loading={loading}
        content={modalContent}
      />
    </>
  );
};

export default CreatePool;
