import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TableSortLabel, Typography, Box, styled
} from '@mui/material';
import axios from 'axios';

const TableContainerStyled = styled(TableContainer)({
  backgroundColor: '#32384B',
  color: '#FFFFFF',
  border: '1.5px solid #FFFFFF',
  borderRadius: '8px',
  overflow: 'hidden',
});

const TableCellStyled = styled(TableCell)({
  border: '1px solid #D3D3D3',
  color: '#FFFFFF',
});

const TableCellHeadStyled = styled(TableCell)({
  backgroundColor: '#060d1f',
  color: '#FFFFFF',
  fontWeight: 'bold',
  border: '1.5px solid #FFFFFF',
  borderBottom: 'none',
  '& .MuiTableSortLabel-root': {
    color: '#FFFFFF !important',
    '&.MuiTableSortLabel-active': {
      color: '#FFFFFF',
    },
    '& .MuiTableSortLabel-icon': {
      color: '#FFFFFF !important',
    },
  },
});

const TableRowHeadStyled = styled(TableRow)({
  '& > th:first-of-type': {
    borderTopLeftRadius: '8px',
  },
  '& > th:last-of-type': {
    borderTopRightRadius: '8px',
  },
});

const TableRowStyled = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#3b3f52',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#2d3143',
  },
});

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('balance');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Uncomment and replace with your actual API endpoint
        // const response = await axios.get('/api/users');
        // setUsers(response.data);

        // Sample data for testing
        setUsers([
          { id: 1, username: 'User1', balance: 1000, spent: 200, lifetimeEarnings: 1200, messages: 10 },
          { id: 2, username: 'User2', balance: 2000, spent: 500, lifetimeEarnings: 2500, messages: 20 },
          { id: 3, username: 'User3', balance: 1500, spent: 300, lifetimeEarnings: 1800, messages: 15 },
          { id: 4, username: 'User4', balance: 500, spent: 100, lifetimeEarnings: 600, messages: 5 },
        ]);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (['balance', 'messages', 'spent', 'lifetimeEarnings'].includes(orderBy)) {
      return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    } else {
      return order === 'asc' ? a[orderBy].localeCompare(b[orderBy]) : b[orderBy].localeCompare(a[orderBy]);
    }
  });

  return (
    <Box sx={{ padding: '16px' }}>
      <Typography variant="h5" color="#FFFFFF" gutterBottom>
        Leaderboard
      </Typography>
      <Typography variant="body1" color="#A0A0A0" paragraph>
        View your top loyalty token holders, spenders and chatters.
      </Typography>
      <TableContainerStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRowHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'username' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'username'}
                  direction={orderBy === 'username' ? order : 'asc'}
                  onClick={() => handleRequestSort('username')}
                >
                  Username
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'balance' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'balance'}
                  direction={orderBy === 'balance' ? order : 'asc'}
                  onClick={() => handleRequestSort('balance')}
                >
                  Balance
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'spent' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'spent'}
                  direction={orderBy === 'spent' ? order : 'asc'}
                  onClick={() => handleRequestSort('spent')}
                >
                  Spent
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'lifetimeEarnings' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'lifetimeEarnings'}
                  direction={orderBy === 'lifetimeEarnings' ? order : 'asc'}
                  onClick={() => handleRequestSort('lifetimeEarnings')}
                >
                  Lifetime Earnings
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'messages' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'messages'}
                  direction={orderBy === 'messages' ? order : 'asc'}
                  onClick={() => handleRequestSort('messages')}
                >
                  Messages
                </TableSortLabel>
              </TableCellHeadStyled>
            </TableRowHeadStyled>
          </TableHead>
          <TableBody>
            {sortedUsers.map((user) => (
              <TableRowStyled key={user.id}>
                <TableCellStyled>{user.username}</TableCellStyled>
                <TableCellStyled>{user.balance}</TableCellStyled>
                <TableCellStyled>{user.spent}</TableCellStyled>
                <TableCellStyled>{user.lifetimeEarnings}</TableCellStyled>
                <TableCellStyled>{user.messages}</TableCellStyled>
              </TableRowStyled>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
    </Box>
  );
};

export default Leaderboard;
