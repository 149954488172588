import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledContainer = styled(Container)({
  padding: '16px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  boxSizing: 'border-box',
  minHeight: '100vh',
  position: 'relative',
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const FeatureTimedMessages = () => {
  return (
    <StyledContainer>
      <Header>
        <Typography variant="h4" gutterBottom>
          Timed Messages
        </Typography>
        <IconButton component={Link} to="/features" sx={{ color: '#FFFFFF' }}>
          <ArrowBackIcon />
        </IconButton>
      </Header>
      <Typography variant="body1" gutterBottom>
        Messages/Commands set to timers to promote important info in an automated manner.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Timed messages are a useful feature in streaming for several reasons:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          1. <strong>Variety in Setup:</strong> Set different amounts of time for each individual message to be repeated or pull from multiple messages every x minutes to keep variety.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. <strong>Regular Updates and Reminders:</strong> Schedule reminders for stream schedules, events, or policy changes.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. <strong>Viewer Interaction and Engagement:</strong> Prompt viewers to follow, join Discord, participate in polls, or subscribe.
        </Typography>
        <Typography variant="body1" gutterBottom>
          4. <strong>Promotion and Marketing:</strong> Promote social media, merchandise, or sponsorships at strategic times.
        </Typography>
        <Typography variant="body1" gutterBottom>
          5. <strong>Moderation and Guidelines:</strong> Reinforce channel guidelines and rules to maintain a positive chat environment.
        </Typography>
        <Typography variant="body1" gutterBottom>
          6. <strong>Event Announcements:</strong> Announce details and updates for special events like charity streams or giveaways.
        </Typography>
        <Typography variant="body1" gutterBottom>
          7. <strong>Technical Support and FAQs:</strong> Provide technical support or answer FAQs.
        </Typography>
        <Typography variant="body1" gutterBottom>
          8. <strong>Content Variety and Diversity:</strong> Announce segment changes or transitions during longer streams.
        </Typography>
        <Typography variant="body1" gutterBottom>
          9. <strong>Brand Consistency:</strong> Maintain consistency in branding and messaging across broadcasts.
        </Typography>
      </Box>
      <Typography variant="body1" gutterBottom>
        Overall, timed messages provide streamers with a versatile tool to automate communication, enhance viewer interaction, and streamline channel management.
      </Typography>
    </StyledContainer>
  );
};

export default FeatureTimedMessages;
