import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledContainer = styled(Container)({
  padding: '16px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  boxSizing: 'border-box',
  minHeight: '100vh',
  position: 'relative',
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SubscriptionOverlay = () => {
  return (
    <StyledContainer>
      <Header>
        <Typography variant="h4" gutterBottom>
          Subscription Goal Overlay
        </Typography>
        <IconButton component={Link} to="/features" sx={{ color: '#FFFFFF' }}>
          <ArrowBackIcon />
        </IconButton>
      </Header>
      <Typography variant="body1" gutterBottom>
        Set a goal controlled by Twitch subscriptions donated to be tracked easily on stream with customization. Use our subscription goal overlay to have a fun way to display and track your subs. Many streamers will combine the goal with an event of some sort and this will help you track your way to that event. Some initial customizations and features include:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          1. <strong>Set goal title and target amount:</strong> Customize the title and the number of subscriptions you aim to achieve.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. <strong>Change the color of the bar:</strong> Adjust the color of the progress bar to match your stream's theme.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. <strong>Set a custom chat message:</strong> Configure a special message to be triggered in chat when the goal is reached.
        </Typography>
      </Box>
      <Typography variant="body1" gutterBottom>
        Seeing subs add up live on stream is a great visual, and adding incentive to a goal in combination with this overlay is a great way to bring new sub revenue to your channel. Future ability will be the option to add sound and overlay to completion of goal.
      </Typography>
    </StyledContainer>
  );
};

export default SubscriptionOverlay;
