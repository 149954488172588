import React from 'react';
import { styled } from '@mui/system';
import { Paper, Grid } from '@mui/material';
import CaptainDegen from '../CaptainDegen.png';
import DeveloperDan from '../DeveloperDan.png';

const Container = styled('div')({
  padding: '10px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  textAlign: 'center',
  boxSizing: 'border-box',
  width: '100%',
  minHeight: '100vh - 122px', // Ensure it takes at least full viewport height
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Center content vertically
  alignItems: 'center', // Center content horizontally
});

const Title = styled('h1')({
  fontSize: '2rem',
  marginBottom: '20px',
  textAlign: 'center', // Ensure the title text itself is centered
});

const MemberCard = styled(Paper)({
  padding: '5px',
  textAlign: 'center',
  backgroundColor: '#1e88e5',
  color: '#FFFFFF',
  width: '80%',
  maxWidth: '400px', // Set a maximum width
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '15px',
  border: '3px solid orange',
  transition: 'transform 0.5s',
  '&:hover': {
    transform: 'scale(1.025)', // Updated hover scale
  },
  '@media (min-width: 600px)': {
    width: '80%', // Width of 80% for larger screens
  },
});

const MemberName = styled('div')({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '10px',
});

const MemberRole = styled('div')({
  fontSize: '1rem',
  marginBottom: '15px',
});

const MemberImage = styled('img')({
  width: '80%', // Reduced image width
  maxHeight: '400px',
  height: 'auto',
  alignSelf: 'center',
});

const MemberDescription = styled('div')({
  fontSize: '0.9rem',
  marginTop: '10px',
});

const Link = styled('a')({
  color: '#060d1f',
  textDecoration: 'underline',
  fontWeight: 'bold',
});

const teamMembers = [
  {
    name: 'Captain Degen',
    role: 'Captain',
    image: CaptainDegen,
    description: (
      <>
        Ahoy, mateys! Meet Captain Degen, the brilliant mastermind behind Scurvy Bot and a legendary Twitch streamer. As the driving force of the Scurvy Bot project, Captain Degen brings innovative ideas to life, creating a revolutionary watch2earn concept that transforms the streaming world. When he's not steering the Scurvy ship, he's entertaining his fearless crew with a wild array of content on Twitch. Join Captain Degen on his thrilling adventures and be a part of the crew on{' '}
        <Link href="https://twitch.tv/captaindegen_" target="_blank" rel="noopener noreferrer">Twitch</Link>. Yo-ho, let the plundering fun begin!
      </>
    ),
  },
  {
    name: 'Dan',
    role: 'Lead Developer',
    image: DeveloperDan,
    description: (
      <>
        Ahoy, ye tech-savvy swabs! Meet Dan, the brilliant developer who dares to transform all of Captain Degen's wildest ideas into reality. With a trusty keyboard and a mind as sharp as a cutlass, Dan navigates the treacherous waters of code to bring innovative features to life. From crafting seamless Web3 integrations to building the legendary watch to earn system, no task is too daunting for our coding maestro. Join us and witness the marvels of Dan's handiwork as he turns the Captain's visions into a digital treasure trove!
      </>
    ),
  },
];

const MeetTheTeam = () => {
  return (
    <Container>
      <Title>Meet the Crew</Title>
      <Grid container spacing={0.5} justifyContent="center">
        {teamMembers.map((member, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} container justifyContent="center">
            <MemberCard>
              <MemberImage src={member.image} alt={`${member.name}`} />
              <MemberName>{member.name}</MemberName>
              <MemberRole>{member.role}</MemberRole>
              <MemberDescription>{member.description}</MemberDescription>
            </MemberCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MeetTheTeam;
