import React, { useState, useEffect } from 'react';
import {
  TableContainerStyled,
  TableCellStyled,
  TableCellHeadStyled,
  TableRowHeadStyled,
  TableRowStyled,
  TablePaginationStyled,
  ImageModal,
  ImageContainer,
  CloseButton,
  ImageTitle,
} from './MasterStyles';
import { styled } from '@mui/system';
import axios from 'axios';
import Cookies from 'js-cookie';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Typography, Box, Button, Table, TableHead, TableBody, Paper, TableSortLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StreamerNFTTransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('transfer_id');
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [imageTitle, setImageTitle] = useState('');

  useEffect(() => {
    const fetchTotalTransactions = async () => {
      try {
        const response = await axios.get('/api/streamer-nft-transactions/count', {
          withCredentials: true,
        });

        setTotalTransactions(response.data.total_count);
      } catch (error) {
        console.error('Error fetching total transaction count:', error);
      }
    };

    fetchTotalTransactions();
  }, []);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('/api/streamer-nft-transactions', {
          withCredentials: true,
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: orderBy,
            sort_order: order,
          },
        });

        setTransactions(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, [page, rowsPerPage, orderBy, order]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleImageClick = (transaction) => {
    if (transaction.image) {
      const imageUrl = `https://atomichub-ipfs.com/ipfs/${transaction.image}`;
      setImageSrc(imageUrl);
      setImageTitle(transaction.name);
      setImageModalOpen(true);
    }
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setImageSrc('');
    setImageTitle('');
  };

  const exportToCSV = async () => {
    try {
      const response = await axios.get('/api/streamer-nft-transactions/count', {
        withCredentials: true,
      });
      const totalTransactions = response.data.total_count;

      let allTransactions = [];
      const totalPages = Math.ceil(totalTransactions / rowsPerPage);
      for (let page = 0; page < totalPages; page++) {
        const response = await axios.get('/api/streamer-nft-transactions', {
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: orderBy,
            sort_order: order,
          },
        });
        allTransactions = allTransactions.concat(response.data);
      }

      const headers = ['Type', 'Asset ID', 'Transfer ID', 'Image', 'Collection', 'Name', 'Wallet', 'Date', 'Transaction Reference', 'Dig Twitch ID'];
      const rows = allTransactions.map(transaction => [
        transaction.type,
        transaction.asset_id,
        transaction.transfer_id,
        transaction.image,
        transaction.collection,
        transaction.name.replace(/"/g, '""'),
        transaction.wallet,
        transaction.date,
        `https://waxblock.io/transaction/${transaction.trx_id}`,
        transaction.dig_twitch_id,
      ]);

      const csvContent = [
        headers.join(","),
        ...rows.map(row => row.map(cell => `"${cell}"`).join(","))
      ].join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `transactions_${Math.floor(Date.now() / 1000)}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to CSV:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" color="#FFFFFF" gutterBottom>
          NFT Transaction History
        </Typography>
        <Button
          variant="contained"
          onClick={exportToCSV}
          disabled={transactions.length === 0}
          sx={{ margin: '10px', backgroundColor: '#ff7e04', '&:hover': { backgroundColor: '#cc6503' } }}
        >
          <FileDownloadOutlinedIcon />
          &nbsp;Export
        </Button>
      </Box>
      <Typography variant="body1" color="#A0A0A0" paragraph>
        View your NFT transaction history.
      </Typography>
      <TableContainerStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRowHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'type' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'type'}
                  direction={orderBy === 'type' ? order : 'asc'}
                  onClick={() => handleRequestSort('type')}
                >
                  Type
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'asset_id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'asset_id'}
                  direction={orderBy === 'asset_id' ? order : 'asc'}
                  onClick={() => handleRequestSort('asset_id')}
                >
                  Asset ID
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'transfer_id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'transfer_id'}
                  direction={orderBy === 'transfer_id' ? order : 'asc'}
                  onClick={() => handleRequestSort('transfer_id')}
                >
                  Transfer ID
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled>Image</TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'collection' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'collection'}
                  direction={orderBy === 'collection' ? order : 'asc'}
                  onClick={() => handleRequestSort('collection')}
                >
                  Collection
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'name' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'wallet' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'wallet'}
                  direction={orderBy === 'wallet' ? order : 'asc'}
                  onClick={() => handleRequestSort('wallet')}
                >
                  Wallet
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled sortDirection={orderBy === 'date' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'date'}
                  direction={orderBy === 'date' ? order : 'desc'}
                  onClick={() => handleRequestSort('date')}
                >
                  Date
                </TableSortLabel>
              </TableCellHeadStyled>
              <TableCellHeadStyled>Transaction Reference</TableCellHeadStyled>
              <TableCellHeadStyled>Dig Twitch ID</TableCellHeadStyled>
            </TableRowHeadStyled>
          </TableHead>
          <TableBody>
            {transactions.map((transaction, index) => (
              <TableRowStyled key={index}>
                <TableCellStyled>{transaction.type}</TableCellStyled>
                <TableCellStyled>
                  <a
                    href={`https://atomichub.io/explorer/asset/wax-mainnet/${transaction.asset_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                  >
                    {transaction.asset_id}
                  </a>
                </TableCellStyled>
                <TableCellStyled>{transaction.transfer_id}</TableCellStyled>
                <TableCellStyled>
                  {transaction.image && (transaction.image.endsWith('.mp4') || transaction.image.endsWith('.webm')) ? (
                    <video
                      src={`https://atomichub-ipfs.com/ipfs/${transaction.image}`}
                      alt={transaction.name}
                      style={{ maxHeight: '50px', maxWidth: '50px', height: 'auto', width: 'auto', objectFit: 'contain' }}
                      controls
                    />
                  ) : (
                    transaction.image && (
                      <img
                        src={`https://atomichub-ipfs.com/ipfs/${transaction.image}`}
                        alt={transaction.name}
                        style={{ maxHeight: '50px', maxWidth: '50px', height: 'auto', width: 'auto', objectFit: 'contain', cursor: 'pointer' }}
                        onClick={() => handleImageClick(transaction)}
                      />
                    )
                  )}
                </TableCellStyled>
                <TableCellStyled>{transaction.collection}</TableCellStyled>
                <TableCellStyled>{transaction.name}</TableCellStyled>
                <TableCellStyled>
                  <a
                    href={`https://atomichub.io/profile/wax-mainnet/${transaction.wallet}?blockchain=wax-mainnet&order=desc&sort=transferred#inventory`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                  >
                    {transaction.wallet}
                  </a>
                </TableCellStyled>
                <TableCellStyled>{formatDate(transaction.date)}</TableCellStyled>
                <TableCellStyled>
                  <a
                    href={`https://waxblock.io/transaction/${transaction.trx_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                  >
                    {transaction.trx_id}
                  </a>
                </TableCellStyled>
                <TableCellStyled>{transaction.dig_twitch_id}</TableCellStyled>
              </TableRowStyled>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <TablePaginationStyled
        component="div"
        count={totalTransactions}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
      <ImageModal
        open={imageModalOpen}
        onClose={handleImageModalClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <ImageContainer>
          <ImageTitle variant="h6">{imageTitle}</ImageTitle>
          <img src={imageSrc} alt="Enlarged" style={{ maxHeight: '40vh', maxWidth: '80vw' }} />
          <CloseButton onClick={handleImageModalClose}>
            <CloseIcon />
          </CloseButton>
        </ImageContainer>
      </ImageModal>
    </Box>
  );
};

export default StreamerNFTTransactionHistory;
