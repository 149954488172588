import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableHead, Paper, Typography, Box, IconButton, Button, Checkbox, TableContainer, TableCell, TableRow, TablePagination, TableSortLabel, Modal, TextField, CircularProgress
} from '@mui/material';
import axios from 'axios';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const DigPool = ({ authState }) => {
  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [result, setResult] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('date');
  const [totalAssets, setTotalAssets] = useState(0);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [imageTitle, setImageTitle] = useState('');

  useEffect(() => {
    console.log('authState in DigPool:', authState);
  }, [authState]);

  const fetchTotalAssets = async () => {
    try {
      const response = await axios.get('/api/nft-assets/count', {
        withCredentials: true,
      });

      setTotalAssets(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total assets count:', error);
    }
  };

  const fetchAssets = async () => {
    try {
      const response = await axios.get('/api/nft-assets', {
        withCredentials: true,
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          sort_by: sortBy,
          sort_order: sortOrder,
        },
      });

      setAssets(response.data);
    } catch (error) {
      console.error('Error fetching assets:', error);
    }
  };

  useEffect(() => {
    fetchTotalAssets();
  }, []);

  useEffect(() => {
    fetchAssets();
  }, [page, rowsPerPage, sortBy, sortOrder]);

  const refreshData = () => {
    fetchTotalAssets();
    fetchAssets();
    setSelectedAssets([]); // Clear selection
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = assets.map((n) => n.asset_id);
      setSelectedAssets(newSelecteds);
      return;
    }
    setSelectedAssets([]);
  };

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedAssets.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAssets, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAssets.slice(1));
    } else if (selectedIndex === selectedAssets.length - 1) {
      newSelected = newSelected.concat(selectedAssets.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAssets.slice(0, selectedIndex),
        selectedAssets.slice(selectedIndex + 1),
      );
    }

    setSelectedAssets(newSelected);
  };

  const handleWithdrawSelected = () => {
    if (selectedAssets.length === 0) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setResult(null);
    setLoadingMessage('');
    refreshData();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const isSelected = (id) => selectedAssets.indexOf(id) !== -1;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const exportToCSV = async () => {
    try {
      if (!authState || !authState.twitch_id) {
        console.error('No twitch_id found in authState.');
        return;
      }

      const countResponse = await axios.get('/api/nft-assets/count', {
        withCredentials: true,
      });
      const totalAssets = countResponse.data.total_count;

      let allAssets = [];
      const totalPages = Math.ceil(totalAssets / rowsPerPage);
      for (let page = 0; page < totalPages; page++) {
        const response = await axios.get('/api/nft-assets', {
          withCredentials: true,
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: sortBy,
            sort_order: sortOrder,
          },
        });
        allAssets = allAssets.concat(response.data);
      }

      const headers = ['Asset ID', 'Image', 'Collection', 'Name', 'Date'];
      const rows = allAssets.map(asset => [
        asset.asset_id,
        `https://atomichub-ipfs.com/ipfs/${asset.image}`,
        asset.collection,
        asset.name.replace(/"/g, '""'),
        formatDate(asset.date),
      ]);

      const csvContent = [
        headers.join(","),
        ...rows.map(row => row.map(cell => `"${cell}"`).join(","))
      ].join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `nft_assets_${Math.floor(Date.now() / 1000)}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to CSV:', error);
    }
  };

  const handleDepositClick = () => {
    if (!authState || !authState.twitch_id) {
      console.error('No twitch_id found in authState.');
      return;
    }
    const memo = `deposit-${authState.twitch_id}`;
    const url = `https://wax.atomichub.io/trading/nft-transfer?partner=dig.sb&memo=${encodeURIComponent(memo)}`;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImageClick = (asset) => {
    if (asset.image) {
      const imageUrl = `https://atomichub-ipfs.com/ipfs/${asset.image}`;
      setImageSrc(imageUrl);
      setImageTitle(`${asset.name}`);
      setImageModalOpen(true);
    }
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setImageSrc('');
    setImageTitle('');
  };
  
  const formik = useFormik({
    initialValues: {
      walletAddress: '',
    },
    validationSchema: Yup.object({
      walletAddress: Yup.string()
        .matches(/^[a-z1-5.]{1,12}$/, 'Invalid WAX wallet address.')
        .required('WAX wallet address is required.'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setLoadingMessage('Processing your withdrawal...');
      try {
        const response = await axios.post(
          '/api/transfer-nft',
          {
            to: values.walletAddress,
            asset_ids: selectedAssets,
            memo: `withdraw-${authState.twitch_id}`,
          },
          {
            withCredentials: true
          }
        );
    
        if (response.data.success) {
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for an additional 2 seconds
          setResult(`NFT transfer successful. Transaction ID: ${response.data.result.trx_id}`);
          refreshData();
        } else {
          setResult(`Error during NFT transfer: ${response.data.message}`);
        }
      } catch (error) {
        setResult(`Error during NFT transfer: ${error.message}`);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box 
      sx={{ padding: '16px' }}>
        <Typography variant="h5" color="#FFFFFF" gutterBottom>
          NFT Dig Pool
        </Typography>
        <IconButton
          color="inherit"
          onClick={refreshData}
        >
          <RefreshIcon />
        </IconButton>
      <Typography variant="body1" color="#A0A0A0" paragraph>
        Manage your digital assets here. Select assets to withdraw or click on an individual asset to view details.
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button
            variant="contained"
            onClick={handleDepositClick}
          >
            DEPOSIT
          </Button>
          <Button
            variant="contained"
            onClick={handleWithdrawSelected}
            disabled={selectedAssets.length === 0}
          >
            WITHDRAW
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={exportToCSV}
            disabled={assets.length === 0}
          >
            <FileDownloadOutlinedIcon />
            &nbsp;Export
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selectedAssets.length > 0 && selectedAssets.length < assets.length}
                  checked={assets.length > 0 && selectedAssets.length === assets.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'asset_id'}
                  direction={sortBy === 'asset_id' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('asset_id')}
                >
                  Asset ID
                </TableSortLabel>
              </TableCell>
              <TableCell>Image</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'collection'}
                  direction={sortBy === 'collection' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('collection')}
                >
                  Collection
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'name'}
                  direction={sortBy === 'name' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'date'}
                  direction={sortBy === 'date' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('date')}
                >
                  Date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets.map((asset) => {
              const isItemSelected = isSelected(asset.asset_id);
              return (
                <TableRow
                  key={asset.asset_id}
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onChange={(event) => handleCheckboxClick(event, asset.asset_id)}
                      inputProps={{ 'data-id': asset.asset_id }}
                    />
                  </TableCell>
                  <TableCell>
                    <a
                      href={`https://atomichub.io/explorer/asset/wax-mainnet/${asset.asset_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#FFFFFF', textDecoration: 'underline' }}
                    >
                      {asset.asset_id}
                    </a>
                  </TableCell>
                  <TableCell>
                    {asset.image && (asset.image.endsWith('.mp4') || asset.image.endsWith('.webm')) ? (
                      <video
                        src={`https://atomichub-ipfs.com/ipfs/${asset.image}`}
                        alt={asset.name}
                        style={{ maxHeight: '50px', maxWidth: '50px', height: 'auto', width: 'auto', objectFit: 'contain' }}
                        controls
                      />
                    ) : (
                      asset.image && (
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${asset.image}`}
                          alt={asset.name}
                          style={{ maxHeight: '50px', maxWidth: '50px', height: 'auto', width: 'auto', objectFit: 'contain', cursor: 'pointer' }}
                          onClick={() => handleImageClick(asset)}
                        />
                      )
                    )}
                  </TableCell>
                  <TableCell>{asset.collection}</TableCell>
                  <TableCell>{asset.name}</TableCell>
                  <TableCell>{formatDate(asset.date)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalAssets}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ 
          backgroundColor: '#060d1f', 
          padding: '20px', 
          borderRadius: '8px', 
          width: '400px', 
          margin: 'auto', 
          marginTop: '10%',
          position: 'relative'
        }}>
          <IconButton 
            sx={{ 
              position: 'absolute', 
              top: '10px', 
              right: '10px', 
              color: '#FFFFFF' 
            }} 
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {loading ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress size={50} sx={{ color: '#ff7e04' }} />
              <Typography variant="h6" color="#FFFFFF" mt={2}>{loadingMessage}</Typography>
            </Box>
          ) : result ? (
            <Box>
              <Typography variant="h6" color="#FFFFFF">Withdrawing assets from Dig Pool</Typography>
              <Typography variant="body1" color="#FFFFFF">
                {result}
                {result.includes('Transaction ID') && (
                  <Typography component="a" href={`https://waxblock.io/transaction/${result.split('Transaction ID: ')[1]}`} target="_blank" rel="noopener noreferrer">
                    View Transaction
                  </Typography>
                )}
              </Typography>
            </Box>
          ) : (
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Typography variant="h6" color="#FFFFFF">Withdrawing assets from Dig Pool</Typography>
              <Typography variant="subtitle1" color="#FFFFFF">You are withdrawing {selectedAssets.length} assets</Typography>
              <TextField
                label="WAX Wallet Address"
                variant="outlined"
                name="walletAddress"
                value={formik.values.walletAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                required
                error={formik.touched.walletAddress && Boolean(formik.errors.walletAddress)}
                helperText={formik.touched.walletAddress && formik.errors.walletAddress}
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
      <Modal
        open={imageModalOpen}
        onClose={handleImageModalClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={{ 
          backgroundColor: '#060d1f', 
          padding: '20px', 
          borderRadius: '8px', 
          maxWidth: '80vw', 
          maxHeight: '40vh', 
          margin: 'auto', 
          marginTop: '10%', 
          position: 'relative', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center'
        }}>
          <Typography variant="h6" color="#FFFFFF">{imageTitle}</Typography>
          <img src={imageSrc} alt="Enlarged" style={{ maxHeight: '40vh', maxWidth: '80vw' }} />
          <IconButton 
            sx={{ 
              position: 'absolute', 
              top: '10px', 
              right: '10px', 
              color: '#FFFFFF' 
            }} 
            onClick={handleImageModalClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </Box>
  );
};

export default DigPool;
