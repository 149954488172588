import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledContainer = styled(Container)({
  padding: '16px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  boxSizing: 'border-box',
  minHeight: '100vh',
  position: 'relative',
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const LoyaltyToken = () => {
  return (
    <StyledContainer>
      <Header>
        <Typography variant="h4" gutterBottom>
          Loyalty Token
        </Typography>
        <IconButton component={Link} to="/features" sx={{ color: '#FFFFFF' }}>
          <ArrowBackIcon />
        </IconButton>
      </Header>
      <Typography variant="body1" gutterBottom>
        A fully customizable channel loyalty token packed with Web2 & Web3 features. Control many great overlays and features with our custom loyalty token. Have your community earn your branded loyalty chat currency fully customized by you the streamer.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Here are some of the unique ways you can use your new loyalty token:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          1. With many varieties of giveaway & raffle overlays, use your token as a way to let them spend to get in. This would allow you to control the value of your giveaways, as well as allowing long time members the chance to get more tickets with their earnings.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. Command usage. You could add a token cost to your custom Scurvy Bot commands. This unlocks a whole world of possibilities of chat control with your currency. These commands could make certain messages or overlays appear. A streamer could link commands to sounds or actions with all kinds of different ways and 3rd party tools. This will all play a big part as more integration is added with our custom commands.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. Digital reward digs. You can set the cost of currency for viewers to dig. This is a feature that can reward digital rewards from NFTs of viewer art or your clips, to codes such as discount or steam game codes!!! This allows you to control how valuable and often you would like viewers to dig.
        </Typography>
        <Typography variant="body1" gutterBottom>
          4. Chat loyalty currency to Blockchain Token. This is the heart of our unique Web3 Watch2Earn concept utilizing the amazing WAX Blockchain. Through Scurvy Bot you can create a WAX wallet thanks to the WAX Team themselves for free. You can then create your own channel branded token on the WAX Blockchain all from your streamer SB Dashboard!!! Then you are able to allow viewers to exchange their earned chat loyalty token to your Blockchain token at your defined rate all automatically from chat!!! This unlocks a whole new world of rewarding your community and brings unique value to your channel.
        </Typography>
        <Typography variant="body1" gutterBottom>
          5. Store. Every streamer will have their own store where they can load digital rewards of all kinds and this store will be viewable by viewers through the SB viewer dashboard. This could range from IRL items sent by streamer to NFTs or Codes sent directly through SB after being bought.
        </Typography>
      </Box>
      <Typography variant="h6" gutterBottom>
        Customization options:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          1. Name - Give your loyalty token its own name to match your Brand.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. Earn rate - Fully control how much is given to streamers. Not just by streamer but many factors such as bonus amounts for subs, bits and raids as well as higher earning for chatters and subscribers.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. Commands - Give custom names to currency commands such as !balance !gold.
        </Typography>
        <Typography variant="body1" gutterBottom>
          4. Verification - Decide if viewers must be registered with Scurvy Bot’s thorough verification system to earn. This allows you to combat multi accounts abusing your valuable currency while preventing having to lock people out of your chat with Twitch’s verification.
        </Typography>
      </Box>
    </StyledContainer>
  );
};

export default LoyaltyToken;
