import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Checkbox, Box } from '@mui/material';
import { styled } from '@mui/system';

const TableContainerStyled = styled(TableContainer)({
  backgroundColor: '#32384B',
  color: '#FFFFFF',
  border: '1.5px solid #FFFFFF',
  borderRadius: '8px',
  overflow: 'hidden',
});

const TableCellStyled = styled(TableCell)(({ theme, disabled }) => ({
  border: '1px solid #D3D3D3',
  color: disabled ? theme.palette.grey[500] : '#FFFFFF',
  width: '80px',
}));

const TableCellHeadStyled = styled(TableCell)({
  backgroundColor: '#060d1f',
  color: '#FFFFFF',
  fontWeight: 'bold',
  border: '1.5px solid #FFFFFF',
  borderBottom: 'none',
  '& .MuiTableSortLabel-root': {
    color: '#FFFFFF !important',
    '&.MuiTableSortLabel-active': {
      color: '#FFFFFF',
    },
    '& .MuiTableSortLabel-icon': {
      color: '#FFFFFF !important',
    },
  },
});

const TableRowHeadStyled = styled(TableRow)({
  '& > th:first-of-type': {
    borderTopLeftRadius: '8px',
  },
  '& > th:last-of-type': {
    borderTopRightRadius: '8px',
  },
});

const TableRowStyled = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#3b3f52',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#2d3143',
  },
});

const Settings = () => {
  const [features, setFeatures] = useState({
    dig: false,
    redeem: false,
    bonusChatEvents: false,
    chestsCommand: false,
    chestsMath: false,
    bossBattle: false,
  });

  const handleFeatureChange = (event) => {
    const { name, checked } = event.target;

    setFeatures((prevFeatures) => {
      if (name === 'chestsCommand' && checked) {
        return {
          ...prevFeatures,
          chestsCommand: checked,
          chestsMath: false,
        };
      }

      if (name === 'chestsMath' && checked) {
        return {
          ...prevFeatures,
          chestsMath: checked,
          chestsCommand: false,
        };
      }

      return {
        ...prevFeatures,
        [name]: checked,
      };
    });
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Typography variant="h5" color="#FFFFFF" gutterBottom>
        Settings
      </Typography>
      <TableContainerStyled component={Paper}>
        <Table>
          <TableHead>
            <TableRowHeadStyled>
              <TableCellHeadStyled>Feature Description</TableCellHeadStyled>
              <TableCellHeadStyled>Enabled</TableCellHeadStyled>
            </TableRowHeadStyled>
          </TableHead>
          <TableBody>
            <TableRowStyled>
              <TableCellStyled>Dig - Allow viewers to dig for NFTs from your pool with loyalty tokens</TableCellStyled>
              <TableCellStyled>
                <Checkbox
                  checked={features.dig}
                  onChange={handleFeatureChange}
                  name="dig"
                  sx={{
                    color: '#FFFFFF',
                    '&.Mui-checked': {
                      color: '#ff7e04',
                    },
                  }}
                />
              </TableCellStyled>
            </TableRowStyled>
            <TableRowStyled>
              <TableCellStyled>Redeem - Allow viewers to redeem loyalty tokens for real blockchain tokens</TableCellStyled>
              <TableCellStyled>
                <Checkbox
                  checked={features.redeem}
                  onChange={handleFeatureChange}
                  name="redeem"
                  sx={{
                    color: '#FFFFFF',
                    '&.Mui-checked': {
                      color: '#ff7e04',
                    },
                  }}
                />
              </TableCellStyled>
            </TableRowStyled>
            <TableRowStyled>
              <TableCellStyled>Bonus chat events - Fun and interactive ways for chatters to earn bonus loyalty tokens</TableCellStyled>
              <TableCellStyled>
                <Checkbox
                  checked={features.bonusChatEvents}
                  onChange={handleFeatureChange}
                  name="bonusChatEvents"
                  sx={{
                    color: '#FFFFFF',
                    '&.Mui-checked': {
                      color: '#ff7e04',
                    },
                  }}
                />
              </TableCellStyled>
            </TableRowStyled>
            <TableRowStyled>
              <TableCellStyled disabled={!features.bonusChatEvents}>
                Chests (command) - Randomly chests (1 per 10 viewers) will appear which require the viewer to type a command to open for bonus loyalty tokens
              </TableCellStyled>
              <TableCellStyled disabled={!features.bonusChatEvents}>
                <Checkbox
                  checked={features.chestsCommand}
                  onChange={handleFeatureChange}
                  name="chestsCommand"
                  disabled={!features.bonusChatEvents}
                  sx={{
                    color: features.bonusChatEvents ? '#FFFFFF' : '#A0A0A0',
                    '&.Mui-checked': {
                      color: features.bonusChatEvents ? '#ff7e04' : '#A0A0A0',
                    },
                  }}
                />
              </TableCellStyled>
            </TableRowStyled>
            <TableRowStyled>
              <TableCellStyled disabled={!features.bonusChatEvents}>
                Chests (math) - Randomly chests (1 per 10 viewers) will appear which require the viewer to solve a math problem to open for bonus loyalty tokens
              </TableCellStyled>
              <TableCellStyled disabled={!features.bonusChatEvents}>
                <Checkbox
                  checked={features.chestsMath}
                  onChange={handleFeatureChange}
                  name="chestsMath"
                  disabled={!features.bonusChatEvents}
                  sx={{
                    color: features.bonusChatEvents ? '#FFFFFF' : '#A0A0A0',
                    '&.Mui-checked': {
                      color: features.bonusChatEvents ? '#ff7e04' : '#A0A0A0',
                    },
                  }}
                />
              </TableCellStyled>
            </TableRowStyled>
            <TableRowStyled>
              <TableCellStyled disabled={!features.bonusChatEvents}>
                Boss battle - Randomly a boss will appear that requires the viewers to use !attack to share the loot. Requires x attacks per 10 viewers to succeed else no rewards
              </TableCellStyled>
              <TableCellStyled disabled={!features.bonusChatEvents}>
                <Checkbox
                  checked={features.bossBattle}
                  onChange={handleFeatureChange}
                  name="bossBattle"
                  disabled={!features.bonusChatEvents}
                  sx={{
                    color: features.bonusChatEvents ? '#FFFFFF' : '#A0A0A0',
                    '&.Mui-checked': {
                      color: features.bonusChatEvents ? '#ff7e04' : '#A0A0A0',
                    },
                  }}
                />
              </TableCellStyled>
            </TableRowStyled>
          </TableBody>
        </Table>
      </TableContainerStyled>
    </Box>
  );
};

export default Settings;
