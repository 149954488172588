import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledContainer = styled(Container)({
  padding: '16px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  boxSizing: 'border-box',
  minHeight: '100vh',
  position: 'relative',
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TimerOverlay = () => {
  return (
    <StyledContainer>
      <Header>
        <Typography variant="h4" gutterBottom>
          Timer Overlays
        </Typography>
        <IconButton component={Link} to="/features" sx={{ color: '#FFFFFF' }}>
          <ArrowBackIcon />
        </IconButton>
      </Header>
      <Typography variant="body1" gutterBottom>
        Fun and interactive timers to show on stream for many use cases including Streamathons. Streamers have many uses for timers and Scurvy Bot aims to solve them all. Timers can be a fun and interactive way for streamers to engage with their audience and automate certain activities or reminders during their broadcasts. Here’s some types of timers we offer, as well as ways you can use and customize them:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          1. <strong>Static Timer:</strong> This is a timer that will be displayed on stream and will count down from a set time. Users can easily run this with a simple command as well as set if other user levels can run in commands. You can also have the ability to add or subtract time live.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. <strong>Stopwatch:</strong> Run a simple command to display a counter going up by default or at a set time. As with other timers, you have the ability to control who can set, add, or subtract time.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. <strong>Interactive Stream-A-Thon Timer:</strong> This is a great timer for those that run Streamathons and can help generate revenue automatically. As with all timers, you can set this with a simple command. However, this Streamathon timer has a lot of customizations that the user sets in their dashboard, as well as control from viewers to make it interactive. Viewers will be able to add more time with bits, subs, as well as follows. The amount is fully controlled by the user as well as an optional capped overall time.
        </Typography>
      </Box>
      <Typography variant="body1" gutterBottom>
        These timers can be used in many ways, and Scurvy Bot makes it easy to have access and control to them. Future plans will be to integrate crypto donations into the Streamathon timers, as well as the ability to upload sounds and overlays to trigger upon timer completions.
      </Typography>
    </StyledContainer>
  );
};

export default TimerOverlay;
