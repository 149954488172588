import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';

const OverlayContainer = styled('div')({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#FFFFFF',
});

const EventIcon = styled(StarIcon)({
  fontSize: '4rem',
  marginBottom: '10px',
});

const EventText = styled('div')({
  fontSize: '2rem',
  textAlign: 'center',
});

const WebSocketTest = () => {
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    const socket = new WebSocket('wss://scurvy.dasker.co.uk');

    socket.onmessage = function(event) {
      const data = JSON.parse(event.data);
      console.log('Received event data:', data);
      setEventData(data.eventData);
      
      // Hide the event data after 5 seconds
      setTimeout(() => {
        setEventData(null);
      }, 5000);
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <OverlayContainer style={{ backgroundColor: eventData ? 'rgba(0, 0, 0, 0.5)' : 'transparent' }}>
      {eventData && (
        <>
          <EventIcon />
          <EventText>{JSON.stringify(eventData)}</EventText>
        </>
      )}
    </OverlayContainer>
  );
};

export default WebSocketTest;
