import React from 'react';
import { Container, Typography, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledContainer = styled(Container)({
  padding: '16px',
  color: '#FFFFFF',
  backgroundColor: '#32384B',
  boxSizing: 'border-box',
  minHeight: '100vh',
  position: 'relative',
});

const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const EmoteOverlay = () => {
  return (
    <StyledContainer>
      <Header>
        <Typography variant="h4" gutterBottom>
          Emote Overlay
        </Typography>
        <IconButton component={Link} to="/features" sx={{ color: '#FFFFFF' }}>
          <ArrowBackIcon />
        </IconButton>
      </Header>
      <Typography variant="body1" gutterBottom>
        A fun way to display chat emotes on stream with fun customization. Our emote overlay is a fun way to bring your viewers chat emotes into your stream. As viewers use emotes in chat they will be displayed onto your stream with these customization options being some examples on how you can control it:
      </Typography>
      <Box sx={{ marginLeft: 2 }}>
        <Typography variant="body1" gutterBottom>
          1. <strong>Control who gets to use it:</strong> Depending on chat activity, emotes can become overwhelming. Control this and promote activity by choosing if anyone can show off their emotes, or just viewers such as subscribers or VIPs.
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. <strong>Set how it looks:</strong> You can choose different factors of how it is displayed such as its opacity or if it's static. And if it does move, how fast it moves around as well as how long the emote is displayed.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. <strong>Decide how many emotes you want flying around:</strong> Set a multiplier for the emotes on how many should show for each one used in chat.
        </Typography>
      </Box>
      <Typography variant="body1" gutterBottom>
        Overall, the emote overlay is one of the many ways Scurvy Bot can help bring fun and viewer engagement to your channel.
      </Typography>
    </StyledContainer>
  );
};

export default EmoteOverlay;
