// AuthModal.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Button } from '@mui/material';
import { Close as CloseIcon, Videocam as VideocamIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import twitchLogo from '../glitch_flat_purple.png';

const DialogStyled = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: '#060d1f',
    color: '#FFFFFF',
    padding: '16px',
    position: 'relative',
  },
});

const DialogTitleStyled = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: 0,
  position: 'relative',
});

const CloseIconButton = styled(IconButton)({
  color: '#d60000',
  position: 'absolute',
  top: '1px',
  right: '1px',
});

const DialogContentStyled = styled(DialogContent)({
  paddingTop: '8px',
});

const ButtonStyled = styled(Button)({
  backgroundColor: '#ff7e04',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#cc6503',
  },
});

const LogoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '32px',
});

const TwitchLogo = styled('img')({
  height: '40px',
  marginRight: '16px',
});

const AuthModal = ({ open, onClose }) => {
  const baseUrl = window.location.origin; // Get the base URL dynamically

  const handleLogin = (role) => {
    let oauthURL = '';

    if (role === 'streamer') {
      oauthURL = `https://id.twitch.tv/oauth2/authorize?client_id=qm0qb5mdhtd1qefe4oxuhnoe7pdwcx&redirect_uri=${baseUrl}/auth-callback&response_type=code&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters+moderator:read:followers`;
    } else {
      oauthURL = `https://id.twitch.tv/oauth2/authorize?client_id=qm0qb5mdhtd1qefe4oxuhnoe7pdwcx&redirect_uri=${baseUrl}/auth-callback&response_type=code&scope=user:read:email`;
    }
    
    sessionStorage.setItem('authRole', role); // Store the role in session storage
    window.location.href = oauthURL;
    onClose();
  };

  return (
    <DialogStyled open={open} onClose={onClose}>
      <DialogTitleStyled>
        <LogoContainer>
          <TwitchLogo src={twitchLogo} alt="Twitch Logo" />
          <div>Login with Twitch</div>
        </LogoContainer>
        <CloseIconButton onClick={onClose}>
          <CloseIcon />
        </CloseIconButton>
      </DialogTitleStyled>
      <DialogContentStyled>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ButtonStyled fullWidth variant="contained" onClick={() => handleLogin('streamer')}>
              I'm a streamer
              <VideocamIcon style={{ marginLeft: '10px' }} />
            </ButtonStyled>
          </Grid>
          <Grid item xs={12}>
            <ButtonStyled fullWidth variant="contained" onClick={() => handleLogin('viewer')}>
              I'm a viewer
              <VisibilityIcon style={{ marginLeft: '10px' }} />
            </ButtonStyled>
          </Grid>
        </Grid>
      </DialogContentStyled>
    </DialogStyled>
  );
};

export default AuthModal;
